import Vue from 'vue';

const moment = require('moment');

const state = {
  date: null,
  draft: null,
  answer: null,
};

// actions
const actions = {
  saveDraft({commit, rootState}) {
    if (!window.location.hash || window.location.hash.search('#invisible') === -1) {
      commit('setDraft', {draft: rootState.donation, date: moment().format('x')});
    }
  },
  resetDraft({commit}) {
    commit('resetState');
  }
};

// mutations
const mutations = {
  setDraft(state, {draft, date}) {
    Vue.set(state, 'draft', draft);
    Vue.set(state, 'date', date);

    // Vue.set(state, 'answer', null);
  },
  setAnswer(state, answer) {
    Vue.set(state, 'answer', answer);
  },
  resetState(state) {
    Vue.set(state, 'date', null);
    Vue.set(state, 'draft', null);
    Vue.set(state, 'answer', null);
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
};