import {getFullAddress, searchAddress} from "../../utils/maps";
import {userAccount} from "../../utils/api";

export default {
  methods: {
    getAddressData(addressData, placeResultData) {
      this.formatted_address = placeResultData.formatted_address;
      this.address = addressData;
    },
    saveAddress() {
      let latitude = null;
      let longitude = null;
      this.isSaveAddressProcess = true;
      searchAddress(getFullAddress(this.address)).then(res => {
        if (res && res.geometry && res.geometry.location) {
          latitude = res.geometry.location.lat();
          longitude = res.geometry.location.lng();
        }
      }).finally(() => {
        userAccount.saveAddress(
          this.address.country,
          this.address.administrative_area_level_1,
          this.address.locality,
          this.address.street_number,
          this.address.route,
          this.address.premise,
          this.address.postal_code,
          this.formatted_address,
          latitude,
          longitude
        ).then()
      });
    },
  },
  data() {
    return {
      formatted_address: '',
      address: null,
      addressInit: {
        country: '',
        administrative_area_level_1: '',
        locality: '',
        street_number: '',
        route: '',
        premise: '',
        postal_code: ''
      },
    }
  }
}