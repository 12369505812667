<template>
  <div id="footer">
    <!-- Footer Copyrights -->
    <div class="footer-bottom-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-12" v-if="charityInfo">
            ⚡ Powered by <strong>Harness</strong> | {{charityInfo.charity_name}}
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Copyrights / End -->
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    props: {
      isLogin: Boolean,
      donorDetail: Object,
      charityInfo: Object
    },
  }
</script>