<template>
  <div>
    <!-- Header Container ================================================== -->
    <header id="header-container" class="fullwidth dashboard-header not-sticky">

      <!-- Header -->
      <div id="header">
        <div class="container">
          <!-- Left Side Content -->
          <div class="left-side">
            <!-- Logo -->
            <div v-if="charityInfo" id="logo">

              <router-link v-if="['ImpactPost'].indexOf($route.name)!==-1"
                           :to="{name: 'ImpactPosts'}"
              ><img :src="charityInfo.logo_transparent" alt="">
              </router-link>
              <router-link v-else-if="['Campaign'].indexOf($route.name)!==-1"
                           :to="{name: 'CampaignsList'}"
              ><img :src="charityInfo.logo_transparent" alt="">
              </router-link>
              <a v-else-if="['Donate'].indexOf($route.name)!==-1 && $root.donationScreen === 'amount' && charityInfo && charityInfo.website && !$root.inIframe"
                 :href="charityInfo.website | correctLink"
              ><img :src="charityInfo.logo_transparent" alt="">
              </a>
              <a v-else-if="['Donate'].indexOf($route.name)!==-1 && $root.donationScreen === 'amount'"
                 v-on:click.prevent="$router.go(-1)"
                 href="#"
              ><img :src="charityInfo.logo_transparent" alt=""></a>
              <router-link v-else-if="['DonationFlow'].indexOf($route.name)!==-1 && $root.donationScreen !== 'amount'"
                           :to="{name: $route.name, params: $route.params, query: {new: 'logo'}}"
              ><img :src="charityInfo.logo_transparent" alt=""></router-link>
              <router-link v-else
                           :to="{name: 'Donate', query: {new: 'logo', 'selected-method': DonationTypes.ONE_TIME}}"
              ><img :src="charityInfo.logo_transparent" alt="">
              </router-link>
            </div>

            <!-- Main Navigation -->
            <nav id="navigation"></nav>
            <div class="clearfix"></div>
            <!-- Main Navigation / End -->

          </div>
          <!-- Left Side Content / End -->

          <!-- Right Side Content -->
          <div v-if="!$route.meta || !$route.meta.hideUserControls || $route.meta.hideUserControls!==true"
               class="right-side">

            <!--  User Notifications -->
            <div class="header-widget">
              <!-- Messages -->
              <div class="header-notifications addition-menu" :class="{active: dropdowns['additionMenu']}">
                <div class="header-notifications-trigger">
                  <a href="#" v-on:click.prevent="showDropdown('additionMenu')"
                  ><i><font-awesome-icon :icon="['far', 'bars']"/></i></a>
                </div>

                <!-- Dropdown -->
                <div class="header-notifications-dropdown">

                  <div class="header-notifications-headline">
                    <h4>Main Menu</h4>
                    <a 
                      :href="`${harnessGivingURL}organization`" 
                      target="_blank">
                      <button class="mark-as-read" v-ripple-dark>
                        <i><font-awesome-icon :icon="['far', 'home']"/></i>
                      </button>
                    </a>
                  </div>

                  <ul class="user-menu-small-nav">
                    <li v-if="charityInfo && charityInfo.facebook_page_id">
                      <a :href="`https://www.messenger.com/t/${charityInfo.facebook_page_id}`"
                         target="_blank"
                      ><i><font-awesome-icon :icon="['far', 'comment-alt-dots']" size="sm"/></i> Contact Us</a>
                    </li>
                    <li v-if="charityInfo"><a
                        :href="`${harnessGivingURL}help-center`"
                        target="_blank">
                      <i><font-awesome-icon :icon="['far', 'question-circle']" size="sm" /></i> Help Center</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="allPosts.length" class="header-notifications" :class="{active: dropdowns['impactPosts']}">

                <!-- Trigger -->
                <div class="header-notifications-trigger">
                  <a href="#" v-on:click.prevent="showDropdown('impactPosts')">
                    <i><font-awesome-icon :icon="['far', 'envelope']"/></i>
                    <transition name="fade">
                      <span v-if="unreadPostsCount>0">{{unreadPostsCount}}</span>
                    </transition>
                    <!-- IF all updates are "marked as seen", then the bubble notification is HIDDEN <span>1</span> -->
                  </a>
                </div>

                <!-- Dropdown -->
                <div class="header-notifications-dropdown">

                  <div class="header-notifications-headline">
                    <h4>Latest Updates</h4>
                    <a class="mark-as-read "
                            v-ripple-dark
                            :href="`${harnessGivingURL}help-center`"
                            title="View All Updates"
                            v-tippy="{placement: 'left'}">
                      <i><font-awesome-icon :icon="['far', 'file-alt']"/></i>
                    </a>
                  </div>

                  <div class="header-notifications-content">
                    <div class="header-notifications-scroll">
                      <ul ref="notificationsListWrap">

                        <!-- Product Updates Notification -->
                        <li v-for="onePost in allPosts.slice(0, 3)"
                            :key="onePost.id"
                            class="notifications-not-read">
                          <a
                            :href="`${harnessGivingURL}posts/${onePost.feedback_id}`"
                          >
                            <div class="notification-text padding-left-0">

                              <strong>{{onePost.title}}</strong>
                              <span class="time">{{onePost.created_at | dateFromNow}}</span>
                              <p class="notification-msg-text">{{onePost.body | clearPostBody}}</p>
                            </div>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>

              </div>

            </div>
            <!--  User Notifications / End -->

            <div v-if="!isLogin" class="header-widget">
              <a :href="'#login'"
                 v-on:click.prevent="$emit('open-login-modal')"
                 class="popup-with-zoom-anim log-in-button"
              ><i><font-awesome-icon :icon="['far', 'user-circle']"/></i>
                <span> Log In / Register</span>
              </a>
            </div>

            <!-- User Menu -->
            <div v-else class="header-widget">
              <!-- Messages -->
              <div class="header-notifications user-menu" :class="{active: dropdowns['profile']}">
                <div v-if="donorDetail" class="header-notifications-trigger"
                     title="You can view & manage all of your donations using this menu. Check it out!"
                     ref="userMenuTippy"
                     v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light', trigger: 'manual'}"
                >
                  <a href="#" v-on:click.prevent="showDropdown('profile')"

                  >
                    <div class="user-avatar ">
                      <img
                          :src="donorDetail.profile_photo?donorDetail.profile_photo:require('./user-avatar-placeholder.png')"
                          :alt="`${donorDetail.first_name} ${donorDetail.last_name}`"
                          width="42px"
                          height="42px"
                      >
                    </div>
                  </a>
                </div>

                <!-- Dropdown -->
                <div class="header-notifications-dropdown">

                  <!-- User Status -->
                  <div class="user-status">

                    <!-- User Name / Avatar -->
                    <div v-if="donorDetail" class="user-details">
                      <div class="user-avatar status-online">
                        <img
                            :src="donorDetail.profile_photo?donorDetail.profile_photo:require('./user-avatar-placeholder.png')"
                            alt="">
                      </div>
                      <div class="user-name">
                        <strong class="fw-600">{{donorDetail.first_name}} {{donorDetail.last_name}}</strong><span>{{donorDetail.email}}</span>
                      </div>
                    </div>

                    <!-- User Status Switcher -->
                    <!--div class="status-switch" id="snackbar-user-status">
                      <label class="user-online current-status">Standard</label>
                      <label class="user-invisible">Gamified</label>
                      <span class="status-indicator" aria-hidden="true"></span>
                    </div-->
                  </div>
                  <ul class="user-menu-small-nav">
                    <li>
                      <a :href="`${harnessGivingURL}account/profile`"
                      ><i><font-awesome-icon :icon="['far', 'user-circle']"/></i> My Profile</a>
                    </li>
                    <li>
                      <a :href="`${harnessGivingURL}account/billing`"
                      ><i><font-awesome-icon :icon="['far', 'wallet']"/></i> Payment Methods</a>
                    </li>
                    <li>
                      <a :href="`${harnessGivingURL}account/donations`"
                      ><i><font-awesome-icon :icon="['far', 'chart-bar']"/></i> Donation History</a>
                    </li>
                    <li>
                      <a :href="`${harnessGivingURL}account/recurring`"
                      ><i><font-awesome-icon :icon="['far', 'calendar']"/></i> Monthly Gifts</a>
                    </li>
                    <li>
                      <a :href="`${harnessGivingURL}account/round-ups`"
                      ><i><font-awesome-icon :icon="['far', 'arrow-alt-circle-up']"/></i> Round-Ups</a>
                    </li>
                    <li>
                      <a href="#" v-on:click.prevent="$emit('logout')">
                        <i><font-awesome-icon :icon="['far', 'power-off']"/></i> Logout
                      </a>
                    </li>
                  </ul>

                </div>
              </div>

            </div>
            <!-- User Menu / End -->
          </div>
          <!-- Right Side Content / End -->
        </div>
      </div>
      <!-- Header / End -->

    </header>
    <div class="clearfix"></div>
    <!-- Header Container / End -->
  </div>
</template>

<script>
  import {stickyHeader, getHarnessGivingURL} from '../../utils/utils'
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {EventBus} from '../../utils/event-bus.js';

  const moment = require('moment');

  export default {
    name: 'NavHeader',
    props: {
      isLogin: Boolean,
      donorDetail: Object,
      charityInfo: Object
    },
    mounted() {
      stickyHeader();

      EventBus.$on('show-donor-menu-help', () => {
        if (this.$refs.userMenuTippy) {
          this.$refs.userMenuTippy._tippy.show()
        }
      });
    },
    filters: {
      clearPostBody(text) {
        return text.replace(/(<([^>]+)>)/ig, "").slice(0, 200);
      }
    },
    methods: {
      ...mapActions('posts', [
        'updatePosts',
      ]),
      ...mapMutations('posts', [
        'setPostsLastViewedDate',
      ]),
      // ...mapActions('campaigns', [
      //   'updateCampaigns',
      // ]),
      upPosts() {
        if (this.charityInfo) {
          this.updatePosts({charity_id: this.charityInfo.charity_id});
          // this.updateCampaigns({charity_id: this.charityInfo.charity_id});
        }
      },
      navigate(route) {
        this.closeDropdown();
//        const {href} =  this.$router.resolve(route);
//        window.open(href, '_blank');
        this.$router.push(route).catch(() => {
        });
      },
      closeDropdown() {
        const self = this;
        [...Object.keys(this.dropdowns)].map(key => this.dropdowns[key] = false);
        document.removeEventListener('click', self.documentClick);
      },
      documentClick(e) {
        if (!e.target.closest('.header-notifications')) {
          this.closeDropdown();
        }
      },
      showDropdown(name) {
        const self = this;
        if (this.dropdowns[name]) {
          this.dropdowns[name] = false;
          document.removeEventListener('click', self.documentClick);
        } else {
          if (name === 'impactPosts') {
            if (this.orderedPosts && this.orderedPosts.length) {
              setTimeout(() => this.setPostsLastViewedDate(moment(this.orderedPosts[0].created_at).unix()), 300);
            }
          }

          [...Object.keys(this.dropdowns)].map(key => this.dropdowns[key] = false);
          this.dropdowns[name] = true;
          this.$nextTick(() => document.addEventListener('click', self.documentClick));
        }
      },
    },
    computed: {
      ...mapState({
        allPosts: state => state.posts.allPosts,
        postsLastUpdate: state => state.posts.lastUpdate,
        postsLastViewedDate: state => state.posts.lastViewedDate,
        // allCampaigns: state => state.campaigns.allCampaigns,
      }),
      unreadPostsCount() {
        if (!this.postsLastUpdate || !this.allPosts) {
          return 0;
        }

        return this.orderedPosts.slice(0, 3).reduce((res, item) => res += (moment(item.created_at).unix() > this.postsLastViewedDate ? 1 : 0), 0);
      },

      orderedPosts() {
        if (!this.postsLastUpdate || !this.allPosts) {
          return [];
        }

        let posts = JSON.parse(JSON.stringify(this.allPosts));

        posts.sort((a, b) => moment(b.created_at) - moment(a.created_at));
        return posts;
      },
      harnessGivingURL() {
        return getHarnessGivingURL()
      }
    },
    watch: {
      'charityInfo'() {
        this.upPosts();
      },
    },
    data() {
      return {
        dropdowns: {
          profile: false,
          additionMenu: false,
          messages: false,
          impactPosts: false,
          notifications: false,
        },
      };
    },
  }
</script>

<style lang="scss">
  #logo img {
    max-height: 50px;
    height: auto;
    top: 20%;
    transform: none;
    @media (max-width: 1099px) {
      max-width: none;
    }
  }

  .header-notifications-content .notification-msg-text {
    display: inline-block;
    line-height: 22px;
    font-size: 14px;
    margin: 2px 0;
    overflow: hidden;
    max-height: 48px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .header-notifications-content .notification-text strong {
    font-weight: 600;
    color: #333;
    font-size: 16px;
  }

  .header-notifications-content .notification-text h4 {
    font-weight: 600;
    color: #333;
    font-size: 18px;
  }

  .notification-text span.time {
    font-size: 13px;
    font-style: italic;
    float: right;
    color: #677788;
  }


</style>
<style scoped lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
