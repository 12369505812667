import $ from 'jquery';
import google from 'google';

function CustomMarker(latlng, map, args, markerIco) {
  this.latlng = latlng;
  this.args = args;
  this.markerIco = markerIco;
  this.setMap(map);
}

CustomMarker.prototype = new google.maps.OverlayView();

CustomMarker.prototype.draw = function () {

  let div = this.div;

  if (!div) {

    div = this.div = document.createElement('div');
    div.className = 'map-marker-container';

    div.innerHTML = '<div class="marker-container"><div class="marker-card"></div></div>';

    // Clicked marker highlight
    google.maps.event.addDomListener(div, "click",  () => {
      $('.map-marker-container').removeClass('clicked infoBox-opened');
      google.maps.event.trigger(this, "click");
      $(this).addClass('clicked infoBox-opened');
    });

    if (typeof(this.args.marker_id) !== 'undefined') {
      div.dataset.marker_id = this.args.marker_id;
    }

    const panes = this.getPanes();
    panes.overlayImage.appendChild(div);
  }

  const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

  if (point) {
    div.style.left = (point.x) + 'px';
    div.style.top = (point.y) + 'px';
  }
};

CustomMarker.prototype.remove = function () {
  if (this.div) {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
    $(this).removeClass('clicked');
  }
};

CustomMarker.prototype.getPosition = function () {
  return this.latlng;
};

/**
 * //https://maps.googleapis.com/maps/api/geocode/json?address=201%20N%20Franklin%20St,%20Tampa,%20FL%2033602&key=AIzaSyB57ra7wKdZbEupdA08SFx0UenMRN9h4rU
 *
 * @param address
 * @returns {Promise}
 */
function searchAddress(address) {

  return new Promise((resolve) => {
    if(!address.trim()){
      resolve(null);
    }
    else {

      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({'address': address}, function (results, status) {
        if (status === 'OK') {
          if (results.length) {
            resolve(results[0]);
          }
        } else {
          //not found
          resolve(null);
        }
      });
    }
  });
}

function getFullAddress(address) {
  let result = [];

  if (address) {
    if (address.street_number || address.route) {
      result.push(`${address.street_number} ${address.route}`);
    }
    if (result || address.premise) {
      result.push(`${address.premise}`);
    }
    if (result || address.locality) {
      result.push(`${address.locality}`);
    }
    if (result || (address.administrative_area_level_1 || address.postal_code)) {
      result.push(`${address.administrative_area_level_1} ${address.postal_code}`);
    }
  }

  return result.map(s => s.trim()).filter(Boolean).join(', ');
}

export {CustomMarker, searchAddress, getFullAddress};