export default Object.freeze({
  ONE_TIME: 'one-time',
  ROUNDUP: 'roundup',
  MONTHLY: 'monthly',
  GUEST: "guest",

  TYPES_OMR: 'one-time,monthly,roundup',
  TYPES_MR: 'monthly,roundup',
  TYPES_OM: 'one-time,monthly',
})
