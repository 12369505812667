<template>
  <div v-if="updatePhoneNumber">
    <div class="welcome-text">
      <h3 style="font-size: 26px;">Phone Number</h3>
      <span>Update and save phone number</span>

    </div>
    <form v-on:submit.prevent="savePhoneNumber">
      <div class="input-with-icon-left no-border">
        <i><font-awesome-icon :icon="['far', 'phone-alt']" /></i>
        <masked-input
            type="tel"
            inputmode="numeric"
            class="input-text no-border"
            pattern="[0-9]{3}-[0-9]{3}-(([0-9]{3}\s)|[0-9]{4})"
            name="phone-register"
            v-model="newPhoneNumber"
            :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="true"
            :placeholderChar="'\u2000'"
            :required="true"
            placeholder="Phone Number"
        />
      </div>

      <!-- Button -->
      <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
              type="submit"
      >Save Changes <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>
      <div class="phone-options margin-top-15">
        <p class="margin-bottom-10"><a v-on:click.prevent="updatePhoneNumber = false" href="#">Go Back</a> to the Previous Screen</p>
      </div>
    </form>
  </div>
  <div v-else>

    <div class="welcome-text">
      <h3 style="font-size: 26px;">Confirm Number</h3>
      <span>Type in the 4-digit code we sent to<br>

        <a v-if="newPhoneNumber || phoneNumber" href="#" v-on:click.prevent="" style="font-size: 16px;">
          {{(newPhoneNumber || phoneNumber).replace(/(\d{3})(\d{3})(\d{4,10})/, '$1-$2-$3')}}
        </a>
      </span>
    </div>

    <!-- Form -->
    <form v-on:submit.prevent="submitCode">
      <div class="input-with-icon-left no-border">
        <i><font-awesome-icon :icon="['far', 'check-circle']" /></i>
        <input
               type="tel"
               class="input-text no-border"
               name="code-register"
               placeholder="4-Digit Code"
               v-model="verificationCode"
               required
        />
      </div>

      <!-- Button -->
      <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
              type="submit"
      >Continue <i><font-awesome-icon :icon="['far', 'check-circle']"/></i></button>

      <div class="phone-options margin-top-15">
        <p class="margin-bottom-10">Didn't receive a code?</p>
        <div>
          <a v-on:click.prevent="updatePhoneNumber = true" href="#">Edit Number</a> |
          <a v-on:click.prevent="resendCode" href="#">Resend Code</a> |
          <a v-on:click.prevent="skipStep" href="#">Skip this Step</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import {addPhone, verifyPhone} from '../../utils/api'
  import {showNotification} from "../../utils/utils";
  import MaskedInput from 'vue-text-mask'

  export default {
    name: 'ConfirmPhoneForm',
    props: ['phoneNumber', 'charityInfo'],
    components: {
      MaskedInput,
    },
    methods: {
      skipStep(){
        this.$emit('update-donor-details');
      },
      savePhoneNumber(){
        addPhone(this.newPhoneNumber).then(() => {
          //notification
          this.updatePhoneNumber = false;
        }).catch(err => {
          //show error and go back
          alert(err.responseJSON && err.responseJSON.error || 'Can not send confirmation code to the phone number');
          this.updatePhoneNumber = false;
        });
      },
      resendCode(){
        addPhone(this.phoneNumber).then(() => {
          //notification
          showNotification("The new code has been sent", this.charityInfo && this.charityInfo.primary_color);
        }).catch(err => {
          //show error and skip this step
          alert(err.responseJSON && err.responseJSON.error || 'Can not send confirmation code to the phone number');
          this.$emit('update-donor-details');
        });
      },
      submitCode() {
        verifyPhone(this.verificationCode).then(() => {
          this.$emit('phone-verified');
        }).catch(err => {
          alert(err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.');
        });
      },
    },
    data() {
      return {
        verificationCode: '',
        updatePhoneNumber: false,
        newPhoneNumber: ''
      };
    },
  }
</script>