import * as LDClient from 'launchdarkly-js-client-sdk';

const launchDarkly = (options) => {
    if (!options.sdkKey) {
        throw new Error('No LaunchDarkly SDK key has been provided.');
    }

    let wrapper = {
        _client: options.client || null,

        get ldClient() {
            return this._client;
        },

        async getClient(waitUntilReady = true) {
            const clientPromise = new Promise((resolve) => {
                if (this._client !== null) {
                    resolve(this._client);
                }

                const timer = setInterval(() => {
                    if (this._client !== null) {
                        clearInterval(timer);
                        resolve(this._client);
                    }
                }, 100);
            });

            if (waitUntilReady) {
                const client = await clientPromise;
                await client.waitUntilReady();
                return client;
            } else {
                return clientPromise;
            }
        },

        initialize(context = null) {
            if (this._client !== null) {
                throw new Error('LaunchDarkly SDK has already been initialized!');
            }

            this._client = LDClient.initialize(this.sdkKey, context);
        },

        isInitialized() {
            return this._client !== null;
        }
    };

    return {
        ...options,
        ...wrapper,
    };
}

const initialize = (sdkKey, context = null) => {
    return launchDarkly({ sdkKey }).initialize(context);
}

const deferInitialization = (sdkKey) => {
    return launchDarkly({ sdkKey });
};

export default {
    initialize,
    deferInitialization,
}