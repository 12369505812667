<template>
  <div id="footer" >

    <!-- Footer Top Section -->
    <div class="footer-top-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <!-- Footer Rows Container -->
            <div class="footer-rows-container">

              <!-- Left Side -->
              <div class="footer-rows-left">
                <div class="footer-row">
                  <div class="footer-row-inner footer-logo">
                    <img v-if="charityInfo && charityInfo.logo_transparent"
                         :src="charityInfo.logo_transparent"
                         :alt="charityInfo.charity_name">
                  </div>
                </div>
              </div>

              <!-- Right Side -->
              <div class="footer-rows-right">

                <!-- Social Icons -->
                <div class="footer-row">
                  <div class="footer-row-inner">
                    <ul class="footer-social-links">
                      <li v-if="charityInfo && charityInfo.facebook_page_id">
                        <a :href="`https://www.facebook.com/${charityInfo.facebook_page_id}`"
                           target="_blank"
                           title="Facebook"
                           v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light'}"
                        ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
                      </li>
                      <li v-if="charityInfo && charityInfo.twitter">
                        <a :href="`https://twitter.com/${charityInfo.twitter}`"
                           target="_blank"
                           title="Twitter"
                           v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light'}"
                        ><font-awesome-icon :icon="['fab', 'twitter']"/>
                        </a>
                      </li>
                      <li v-if="charityInfo && charityInfo.instagram">
                        <a :href="`https://www.instagram.com/${charityInfo.instagram}/`"
                           target="_blank"
                           title="Instagram"
                           v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light'}"
                        ><font-awesome-icon :icon="['fab', 'instagram']"/>
                        </a>
                      </li>
                      <li v-if="charityInfo && charityInfo.linkedin">
                        <a :href="`https://www.linkedin.com/company/${charityInfo.linkedin}/`"
                           target="_blank"
                           title="LinkedIn"
                           v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light'}"
                        ><font-awesome-icon :icon="['fab', 'linkedin-in']"/>
                        </a>
                      </li>
                      <li v-if="charityInfo && charityInfo.youtube">
                        <a :href="`https://www.youtube.com/channel/${charityInfo.youtube}`"
                           target="_blank"
                           title="YouTube"
                           v-tippy="{ placement : 'bottom',  arrow: true, theme: 'light'}"
                        ><font-awesome-icon :icon="['fab', 'youtube']"/>
                        </a>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>

            </div>
            <!-- Footer Rows Container / End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Top Section / End -->

<!--    {{charityInfo.twitter}}-->
    <!-- Footer Middle Section -->
    <div v-if="showMiddleBottom" class="footer-middle-section">
      <div class="container">
        <div class="row">

          <!-- Links - Column 1 -->
          <div class="col-xl-2 col-lg-2 col-md-3">
            <div class="footer-links">
              <h3>Main Menu</h3>
              <ul>
                <li v-if="charityInfo && charityInfo.website"><a
                        :href="charityInfo.website | correctLink"><span>Back to Website</span></a></li>
                <li><a href="https://donor-support.drift.help" target="_blank"><span>Help Center</span></a></li>
                <li v-if="charityInfo && charityInfo.facebook_page_id"><a
                        :href="`https://www.messenger.com/t/${charityInfo.facebook_page_id}`"
                        target="_blank"><span>Contact Us</span></a></li>
              </ul>
            </div>
          </div>

          <!-- Links - Column 2 -->
          <div class="col-xl-2 col-lg-2 col-md-3">
            <div class="footer-links">
              <h3>Ways to Give</h3>
              <ul>
                <li>
                  <router-link :to="{name: 'Donate', query: {'selected-method': DonationTypes.ONE_TIME}}"><span>One-Time</span></router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Donate', query: {'selected-method': DonationTypes.MONTHLY}}"><span>Give Monthly</span></router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Donate', query: {'selected-method': DonationTypes.ROUNDUP}}"><span>Round-Ups</span></router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- Links - Column 3 -->
          <div class="col-xl-2 col-lg-2 col-md-3">
            <div class="footer-links">
              <h3>More Options</h3>
              <ul>
                <li><router-link :to="{name: 'CampaignsList'}"><span>Campaigns</span></router-link></li>
                <li><router-link :to="{name: 'ImpactPosts'}"><span>Impact Posts</span></router-link></li>
                <li v-if="charityInfo && charityInfo.facebook_page_id"><a
                        :href="`https://www.facebook.com/pg/${charityInfo.facebook_page_id}/events/`"
                        target="_blank"
                ><span>Upcoming Events</span></a></li>
              </ul>
            </div>
          </div>

          <!-- Links - Column 4 -->
          <div class="col-xl-2 col-lg-2 col-md-3">
            <div class="footer-links">
              <h3>Privacy & Terms</h3>
              <ul>
                <li><router-link :to="{name: 'Privacy'}" target="_blank"><span>Privacy Policy</span></router-link>
                </li>
                <li><router-link :to="{name: 'Terms'}" target="_blank"><span>Terms of Use</span></router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- Newsletter -->
          <div v-if="!isLogin" class="col-xl-4 col-lg-4 col-md-12">
            <h3><i><font-awesome-icon :icon="['far', 'envelope']"/></i> Receive Exclusive Updates</h3>
            <p>Create a Free Donor Account to receive exclusive updates & information from our organization</p>
            <form action="#"
                  v-on:submit.prevent="createAccount"
                  method="get"
                  class="newsletter">
              <input type="email"
                     required
                     v-model="email"
                     name="email"
                     placeholder="Enter your email address">
              <button type="submit"><i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Middle Section / End -->

    <!-- Footer Copyrights -->
    <div v-if="showMiddleBottom" class="footer-bottom-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            ⚡ Powered by <strong><a style="color: #ffffff" href="http://harnessgiving.com/" target="_blank">Harness</a></strong>
            | {{charityInfo?charityInfo.charity_name:''}}
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Copyrights / End -->

  </div>
</template>

<script>
  export default {
    name: 'BigFooter',
    props: {
      isLogin: Boolean,
      donorDetail: Object,
      charityInfo: Object,
      showMiddleBottom: {
        type: Boolean,
        'default': true
      }
    },
    methods:{
      createAccount(){
        this.$emit('open-login-modal', 'register', this.email);
      }
    },
    data() {
      return {
        email: '',
      }
    },

  }
</script>
