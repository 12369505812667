import Vue from 'vue';
import {
  guestDonation,
  createDonationConfirmationCode,
  confirmDonation,
  addDonationNote,
  getDonationFees, recurringDonation
} from '../../utils/api'
import DonationTypes from './donation-types'

const getDefaultState = () => {
  return {
    flow_id: null,
    type: DonationTypes.ONE_TIME,

    screen: 'amount',

    amount: 50,
    amountSet: false,
    donatedAmount: 0,

    isMonthlyAsk: false,//is answered flag
    campaign_id: false,
    designation_id: false,

    first_name: '',
    last_name: '',
    email: '',
    custom_fields_response_token: '',

    stripe_token: '',

    isDone: false,
    donationInfo: null,
    isHonor: false,
    honorInfo: null,

    processingFees: 0,
    is_cover_fee: false,

    guest_token: '',
    chargeId: '',
    note: '',
    note_is_public: true,
    note_is_added: false,

    settings: {
      display_in_honor: true,
      display_donation_note: true,
      display_cover_fees: true,
      cover_fees_default: true,
      smart_ask: true,
    },

    completedDonation: null
  }
};

const state = getDefaultState();

// getters
const getters = {
  processingFees: state => {
    // return Math.round((state.amount / 100 * 2.9 + .3) * 100) / 100;
    return state.processingFees / 100;
  },
};

// actions
const actions = {
  setDonationAmount({commit}, {amount, charity_id, type = 'one-time'}) {
    commit('setAmount', amount * 1);
    getDonationFees(amount * 100, charity_id, type).then(res => {
      commit('setProcessingFees', res.fee);
    })
  },
  addNote({commit, state}, {note, is_public = true}) {
    commit('setNote', {note, is_public});
    return new Promise((resolve, reject) => {
      addDonationNote(
        state.chargeId,
        state.note,
        state.note_is_public,
        state.type === DonationTypes.GUEST ? state.guest_token : false
      ).then(res => {
        commit('noteAdded');
        resolve(res);
      }).catch(err => {
        let errorMsg = err.responseJSON && err.responseJSON.full_error || err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
        alert(errorMsg);
        reject(errorMsg);
      });
    });
  },
  donate({commit, state}, {stripe_token, funding_source_id, charity_id, is_cover_fee = false, captchaKey = ''}) {
    commit('setToken', stripe_token);
    commit('setIsCoverFee', is_cover_fee);

    let totalDonation = state.amount;
    let noteInfo = state.note?({note: state.note, is_public: state.note_is_public}):null;

    return new Promise((resolve, reject) => {
      if (state.type === DonationTypes.GUEST) {
        guestDonation(
          state.stripe_token,
          charity_id,
          Math.round(totalDonation * 100),
          state.email,
          state.first_name,
          state.last_name,
          state.campaign_id || 0,
          state.designation_id || 0,
          state.honorInfo,
          state.is_cover_fee,
          noteInfo,
          captchaKey,
          state.flow_id,
          state.custom_fields_response_token,
        ).then(guestDonationInfo => {
          commit('done', {donated: totalDonation, info: guestDonationInfo});
          commit('donationDraft/resetState', null, { root: true });

          resolve(guestDonationInfo);
        }).catch(err => {
          let errorMsg = err.responseJSON && err.responseJSON.full_error || err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
          alert(errorMsg);
          reject(errorMsg);
        });
      }
      else if (state.type === DonationTypes.ONE_TIME) {
        createDonationConfirmationCode(Math.round(totalDonation * 100)).then(resConfirm => {
          confirmDonation(
            resConfirm.confirmation_code,
            funding_source_id,
            state.campaign_id || 0,
            state.designation_id || 0,
            state.honorInfo,
            state.is_cover_fee,
            noteInfo,
            state.flow_id,
            state.custom_fields_response_token,
          ).then(donationInfo => {
            commit('done', {donated: totalDonation, info: donationInfo});
            commit('donationDraft/resetState', null, { root: true });

            resolve(donationInfo);
          }).catch(err => {
            let errorMsg = err.responseJSON && err.responseJSON.full_error || err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
            alert(errorMsg);
            reject(errorMsg);
          });
        });
      }
      else if (state.type === DonationTypes.MONTHLY) {
        recurringDonation.addRecurringDonation(
          funding_source_id,
          Math.round(totalDonation * 100),
          state.campaign_id || 0,
          state.designation_id || 0,
          state.honorInfo,
          state.is_cover_fee,
          noteInfo,
          state.flow_id,
          state.custom_fields_response_token,
        ).then((donationInfo) => {
          commit('done', {donated: totalDonation, info: donationInfo});
          commit('donationDraft/resetState', null, { root: true });


          resolve(donationInfo);
        })
          .catch(err => {
            let errorMsg = err.responseJSON && err.responseJSON.full_error || err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
            alert(errorMsg);
            reject(errorMsg);
          });
      }
    });
  },
};

// mutations
const mutations = {
  resetState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  setState(state, newVal){
    Object.assign(state, newVal)
  },
  /**
   * set guest donation type
   * @param state
   * @param email
   * @param first_name
   * @param last_name
   */
  goGuest(state, {email, first_name, last_name}) {
    state.type = DonationTypes.GUEST;
    state.email = email;
    state.first_name = first_name;
    state.last_name = last_name;
  },
  setAmount(state, amount) {
    if(!state.flow_id) {
      setQueryStringParameter('amount', amount);
    }
    Vue.set(state, 'amountSet', true);
    Vue.set(state, 'amount', amount);
  },
  setFlowId(state, {flow_id}) {
    Vue.set(state, 'flow_id', flow_id);
  },
  setProcessingFees(state, fee) {
    Vue.set(state, 'processingFees', fee);
  },
  setIsCoverFee(state, is_cover_fee) {
    Vue.set(state, 'is_cover_fee', is_cover_fee);
  },
  setType(state, type) {
    setQueryStringParameter('type', type);
    state.isDone = false;
    Vue.set(state, 'type', type);
    if (type === DonationTypes.ONE_TIME) {
      state.isMonthlyAsk = false;
    }
  },
  setTypePure(state, type) {
    Vue.set(state, 'type', type);
  },
  setCampaignId(state, campaign_id) {
    Vue.set(state, 'campaign_id', campaign_id);
  },
  setDesignationId(state, designation_id) {
    Vue.set(state, 'designation_id', designation_id);
  },
  monthlyAnswer(state) {
    Vue.set(state, 'isMonthlyAsk', true);
  },
  setSettings(state, newSettings){
    Vue.set(state, 'settings', newSettings);
  },
  setCustomFieldsResponseToken(state, custom_fields_response_token){
    Vue.set(state, 'custom_fields_response_token', custom_fields_response_token);
  },
  resetSettings(state){
    Vue.set(state, 'settings', {
      display_in_honor: true,
      display_donation_note: true,
      display_cover_fees: true,
      cover_fees_default: true,
      smart_ask: true,
    });
  },
  setToken(state, token) {
    Vue.set(state, 'stripe_token', token);
  },
  setScreen(state, screen) {
    Vue.set(state, 'screen', screen);
  },
  setNote(state, {note, is_public}) {
    state.note = note;
    state.note_is_public = is_public;
  },
  done(state, {donated, info}) {
    state.isDone = true;
    state.donatedAmount = donated;
    state.donationInfo = info;
    if (state.type === DonationTypes.GUEST) {
      state.guest_token = info.token;
      state.chargeId = info.chargeId;
    }
    else if (state.type === DonationTypes.ONE_TIME) {
      state.chargeId = info.chargeId;
    }
    Vue.set(state, 'completedDonation', Object.assign({}, state));

    state.isHonor = false;
    Vue.set(state, 'honorInfo', null);
    Vue.set(state, 'note', '');
    Vue.set(state, 'note_is_public', true);

  },
  honor(state, info) {
    state.isHonor = true;
    Vue.set(state, 'honorInfo', info);
  },
  noteAdded(state) {
    state.note_is_added = true;
  },
};

function setQueryStringParameter(name, value) {
  const params = new URLSearchParams(location.search);
  params.set(name, value);
  window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`));
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
