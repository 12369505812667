import Vue from 'vue';
import {getAllPublicCampaigns} from '../../utils/api'
import {getDefaultCampaign} from '../../utils/utils'

const moment = require('moment');

const state = {
  allCampaigns: [],
  lastUpdate: null
};

const getters = {
  allCampaignsWithDefault: state => charityInfo => {
    let res = [...state.allCampaigns];
    if (charityInfo) {
      res.unshift(getDefaultCampaign(charityInfo.cover_photo, charityInfo.campaign.description));
    }
    return res;
  },
};

// actions
const actions = {
  updateCampaigns({commit, state}, {charity_id, force = false}) {
    return new Promise((resolve) => {

      //cache for 2 minutes
      if (state.lastUpdate === null || force || moment().diff(state.lastUpdate, 'seconds') > 60 * 2) {
        getAllPublicCampaigns(charity_id).then(campaigns => {
          commit('setCampaigns', campaigns.campaigns);
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  },
};

// mutations
const mutations = {
  setCampaigns(state, allCampaigns) {

    Vue.set(state, 'lastUpdate', moment());
    Vue.set(state, 'allCampaigns', allCampaigns);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};