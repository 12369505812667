import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta'

import { getHarnessGivingURL } from '../utils/utils'

const DonationReceipt = () => import(/* webpackChunkName: "account" */ '../components/Donations/receipt.vue')
const DonationFlow = () => import(/* webpackChunkName: "flow" */ '../components/Flow/flow')
import config from '../config.json';

import DonationTypes from '../store/modules/donation-types'

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  base: config.base_path,
  scrollBehavior(to, from) {
    if (to.name === from.name && from.name === "DonationOptions") {
      return false;
    }
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    { path: "/index.html", redirect: "/donate" },
    { path: "/", redirect: "/donate" },
    {
      path: "/donate",
      // alias: ['/donation-confirmation', '/roundups-confirmation'],
      name: "Donate",
      component: DonationFlow,
      async beforeEnter(to, from, next) {
        let queryParamsString = null;
        let newURL = `${getHarnessGivingURL()}donate`;

        // The redirect feature is now permanently on
        const isFeatureEnabled = true

        if (to.query) {
          const objArray = Object.entries(to.query);
          const queryParams = objArray.map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );

          // Join the array with '&' to create a URL query parameter string
          queryParamsString = queryParams.join("&");
        }

        if (queryParamsString) {
          newURL = `${getHarnessGivingURL()}donate/?${queryParamsString}`;
        }
        if (isFeatureEnabled) {
          window.location.href = newURL;
        } else {
          next()
        }
      },
    },
    {
      path: "/roundups-confirmation",
      name: "RoundupsConfirmation",
      component: DonationFlow,
    },
    {
      path: "/donation-confirmation",
      name: "DonationConfirmation",
      component: DonationFlow,
    },
    {
      path: "/donation/round-ups",
      name: "RoundUpDonation",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_OMR,
          "selected-method": DonationTypes.ROUNDUP,
        },
      },
    },
    {
      path: "/flow/:formId",
      alias: "/checkout/:formId",
      name: "DonationFlow",
    },
    {
      path: "/round-up",
      name: "RoundUpDonation2",
      meta: {
        flowRedirect: { method: DonationTypes.ROUNDUP },
      },
    },
    {
      path: "/donation/round-ups-connect-card",
      name: "RoundupsConnectCard",
      component: DonationFlow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/donation/one-time",
      name: "DonationOneTime",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_OMR,
          "selected-method": DonationTypes.ONE_TIME,
        },
      },
    },
    {
      path: "/one-time",
      name: "DonationOneTime2",
      meta: {
        flowRedirect: { method: DonationTypes.ONE_TIME },
      },
    },
    {
      path: "/donation/monthly",
      name: "DonationMonthly",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_OMR,
          "selected-method": DonationTypes.MONTHLY,
        },
      },
    },
    {
      path: "/monthly",
      name: "DonationMonthly2",
      meta: {
        flowRedirect: { method: DonationTypes.MONTHLY },
      },
    },
    {
      path: "/recurring",
      name: "DonationRecurringMonthly",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_MR,
          "selected-method": DonationTypes.MONTHLY,
        },
      },
    },
    {
      path: "/recurring/round-ups",
      name: "DonationRecurringRoundups",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_MR,
          "selected-method": DonationTypes.ROUNDUP,
        },
      },
    },
    {
      path: "/one-month",
      name: "DonationOneMonth",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_OM,
          "selected-method": DonationTypes.ONE_TIME,
        },
      },
    },
    {
      path: "/one-month/monthly",
      name: "DonationOneMonthMonthly",
      meta: {
        flowRedirect: {
          method: DonationTypes.TYPES_OM,
          "selected-method": DonationTypes.MONTHLY,
        },
      },
    },
    {
      path: "/impact-amounts",
      name: "ImpactAmounts",
      component: () =>
        import(
          /* webpackChunkName: "pages" */ "../components/Donations/impact-amounts"
        ),
    },
    {
      path: "/donation/proceed",
      name: "DonationProceed",
    },
    {
      path: "/keyword-landing",
      name: "KeywordLanding",
      component: () =>
        import(
          /* webpackChunkName: "pages" */ "../components/Pages/keyword-landing"
        ),
      meta: {
        hideUserControls: true,
      },
    },
    {
      path: "/receipt-donation",
      name: "DonationReceipt",
      component: DonationReceipt,
      meta: {
        simpleLayout: true,
      },
    },
    {
      path: "/receipt/:id",
      name: "DonationHistoryReceipt",
      component: DonationReceipt,
      meta: {
        simpleLayout: true,
      },
    },
    {
      path: "/impact-posts",
      name: "ImpactPosts",
      component: () =>
        import(/* webpackChunkName: "post" */ "../components/Posts/list"),
      meta: {
        title: "Impact Posts",
      },
    },
    {
      path: "/impact-posts/:postId",
      name: "ImpactPost",
      component: () =>
        import(/* webpackChunkName: "post" */ "../components/Posts/post"),
    },
    {
      path: "/campaigns-list",
      name: "CampaignsList",
      component: () =>
        import(
          /* webpackChunkName: "post" */ "../components/Campaigns/campaigns-list"
        ),
      meta: {
        bigFooter: true,
      },
    },
    {
      path: "/campaigns-grid",
      name: "CampaignsGrid",
      component: () =>
        import(
          /* webpackChunkName: "post" */ "../components/Campaigns/campaigns-grid"
        ),
    },
    {
      path: "/campaign-categories",
      name: "CampaignsCategories",
      component: () =>
        import(
          /* webpackChunkName: "post" */ "../components/Campaigns/campaign-categories"
        ),
      meta: {
        withFooter: true,
      },
    },
    {
      path: "/campaigns",
      name: "CampaignsListIframe",
      component: () =>
        import(
          /* webpackChunkName: "post" */ "../components/Campaigns/campaigns-list"
        ),
      props: {
        version: "iframe",
      },
      meta: {
        title: "Our Campaigns",
      },
    },
    {
      path: "/campaign/:campaignId",
      name: "Campaign",
      component: () =>
        import(
          /* webpackChunkName: "post" */ "../components/Campaigns/campaign"
        ),
      meta: {
        bigFooter: true,
        showMiddleBottom: false,
      },
    },
    {
      path: "/account/edit",
      name: "AccountEdit",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/account-edit"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/round-ups",
      name: "AccountRoundUps",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/round-ups"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account",
      name: "Account",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/account.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/login.vue"
        ),
      meta: {
        requiresNoAuth: true,
        authLink: "/account",
      },
    },
    {
      path: "/register",
      name: "Register",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/register.vue"
        ),
      meta: {
        requiresNoAuth: true,
        authLink: "/account",
      },
    },
    {
      path: "/texts",
      name: "Texts",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/register.vue"
        ),
      meta: {
        requiresNoAuth: true,
        texts: true,
        authLink: "/account",
      },
    },
    {
      path: "/employment",
      name: "Employment",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/employment"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/employer-matching",
      name: "EmployerMatching",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Profile/employer-matching"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Pages/forgot-password"
        ),
      meta: {
        requiresNoAuth: true,
      },
    },
    {
      path: "/password-reset",
      name: "PasswordReset",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Pages/password-reset"
        ),
      meta: {
        requiresNoAuth: true,
      },
    },
    {
      path: "/terms",
      name: "Terms",
      component: () =>
        import(/* webpackChunkName: "pages" */ "../components/Pages/terms"),
      meta: {
        withFooter: true,
      },
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: () =>
        import(/* webpackChunkName: "pages" */ "../components/Pages/privacy"),
      meta: {
        withFooter: true,
      },
    },
    {
      path: "/invite-friends",
      alias: "/share2care",
      name: "InviteFriends",
      component: () =>
        import(
          /* webpackChunkName: "pages" */ "../components/Pages/invite-friends"
        ),
    },
    { path: "/donation-calculator-willingness", redirect: "/giveup-giveback" },
    {
      path: "/giveup-giveback",
      name: "DonationCalculator",
      component: () =>
        import(
          /* webpackChunkName: "pages" */ "../components/Pages/donation-calculator"
        ),
    },
    {
      path: "/donation-options",
      name: "DonationOptions",
      component: () =>
        import(
          /* webpackChunkName: "pages" */ "../components/Pages/donation-options"
        ),
      meta: {
        bigFooter: true,
      },
    },
    {
      path: "/monthly-donations",
      name: "MonthlyDonations",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Donations/monthly-donations"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/payment-methods",
      name: "PaymentMethods",
      component: () =>
        import(
          /* webpackChunkName: "account" */ "../components/Donations/payment-methods"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: '*',
    //   name: 'NotFound',
    //   component: NotFound,
    // },
  ],
});
