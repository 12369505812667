import {mapMutations, mapState} from "vuex";
import _ from "underscore";

export default {
  methods: {
    ...mapMutations('donationFlow', [
      'setConfig',
    ]),
    standardTypeName(title) {
      return title.replace('one_time', this.DonationTypes.ONE_TIME)
        .replace('roundups', this.DonationTypes.ROUNDUP)
        .replace('roundup', this.DonationTypes.ROUNDUP)
    },
    normalizeFlow(flow) {
//set default value if not exists
      if (!flow.properties.donation.campaigns
        || !flow.properties.donation.campaigns.options
      ) {
        flow.properties.donation = {...flow.properties.donation, campaigns: {options: 'all'}};
      }

      let allowedMethods = flow.properties.donation.allowed_methods.map(this.standardTypeName);

      if (this.$route.query.method) {
        let filterMethods = this.$route.query.method.split(',');

        const intersect = _.intersection(filterMethods, allowedMethods);
        if (intersect.length) {
          allowedMethods = intersect;
        }
        //else ignore GET parameters and use config values
      }
      flow.properties.donation.allowed_methods = [...allowedMethods];


      if (flow.properties.donation.default_method) {
        flow.properties.donation.default_method = this.standardTypeName(flow.properties.donation.default_method);
      }
      return flow;
    },
  },
  computed: {
    ...mapState({
      donationConfig: state => {
        return (state.donationFlow.config && state.donationFlow.config.properties) ? state.donationFlow.config.properties : null
      },
    }),
  }
}