import Vue from 'vue';
import Vuex from 'vuex';
import donation from './modules/donation';
import donationDraft from './modules/donation-draft';
import donationFlow from './modules/donation-flow';
import campaigns from './modules/campaigns';
import designations from './modules/designations';
import donationHistory from './modules/donation-history';
import posts from './modules/posts';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    donation,
    donationFlow,
    campaigns,
    designations,
    donationHistory,
    posts,
    donationDraft,
  },
  strict: debug,
  plugins: [createPersistedState({
    key: 'harness_webapp_db',
    paths: ['posts', 'campaigns', 'donationDraft'],
    setState: (key, state, storage) => {
      try {
        return storage.setItem(key, JSON.stringify(state));
      } catch (e) {
        return null;
      }
    }
  })]
})
