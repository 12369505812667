import Vue from 'vue';

import {
  getDonationFees
} from '../../utils/api'

const state = {
  config: null
};

// actions
const actions = {
  setDonationAmount({commit}, {amount, charity_id, type = 'one-time'}) {
    commit('setAmount', amount);
    getDonationFees(amount * 100, charity_id, type).then(res => {
      commit('setProcessingFees', res.fee);
    })
  },
};

// mutations
const mutations = {
  setConfig(state, config) {
    Vue.set(state, 'config', config);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
