export default {
  methods: {
    getCardImage(brand) {
      brand = brand.toLowerCase();

      switch (brand) {
        case 'amex':
        case 'dinners':
        case 'discover':
        case 'jcb':
        case 'mastercard':
        case 'unionpay':
        case 'visa':
          break;
        case 'american express':
          brand = 'amex';
          break;
        default:
          brand = 'card';
      }

      return require(`./cards/${brand}.svg`);
    },
    getFaName(brand){
      brand = brand.toLowerCase();
      switch (brand) {
        case 'amex':
        case 'discover':
        case 'jcb':
        case 'mastercard':
        case 'visa':
          break;
        case 'american express':
          brand = 'amex';
          break;
        case 'dinners':
          brand = 'diners-club';
          break;

        default:
          brand = 'mastercard';
      }

      return 'cc-' + brand
    }
  }
}