import {getApiPath, getDomain} from "./utils";
import ApiRequest from "./api-request";

function checkErrorLogout(error) {
  if (error && error.responseJSON && (
    error.responseJSON.error === 'Incorrect harness_id' || error.responseJSON.error === 'Invalid JWT')
  ) {
    localStorage.clear();
    window.location.reload();
    return true;
  }
  return false
}


/**
 * get harness_id for JWT
 * @returns {string}
 */
function getHarnessId() {
  const tokenParts = localStorage.jwt.split(".");
  return JSON.parse(window.atob(tokenParts[1])).harness_id;
}

/**
 * get JTW token
 * @returns {string}
 */
function getJwt() {
  return localStorage.jwt;
}

/**
 * get charity info
 * @returns {Promise}
 */
function getWhitelabelReq() {
  return apiRequest.doGetRequest('/whitelabel/' + getDomain() + '/config');
}

/**
 * login
 * @param email
 * @param password
 * @param domain
 * @returns {Promise}
 */
function login(email, password, domain) {
  return new Promise((resolve, reject) => {
    if(window.localStorage.getItem('jwt')) {
      window.location.reload();
      reject('already logged in');
    }
    else {
      window.jQuery.post(getApiPath() + '/login/email', {
        email,
        password,
        domain,
      })
        .done((res) => {
          resolve(res);
        })
        .fail((error) => {
          if (!checkErrorLogout(error)) reject(error);
        });
    }
  });
}

/**
 * sign-up
 * @param email
 * @param password
 * @param domain
 * @param signup_invisible_recaptcha_token
 * @returns {Promise}
 */
function signUp(email, password, domain, signup_invisible_recaptcha_token) {
  return new Promise((resolve, reject) => {
    if(window.localStorage.getItem('jwt')) {
      window.location.reload();
      reject('already logged in');
    }
    else {
      window.jQuery.post(getApiPath() + '/signup/email', {
        email,
        password,
        domain,
        signup_invisible_recaptcha_token,
      })
        .done((res) => {
          resolve(res);
        })
        .fail((error) => {
          if (!checkErrorLogout(error)) reject(error);
        });
    }
  });
}

/**
 * add phone to account and send a confirmation code
 * @param phoneNumber
 * @returns {Promise}
 */
function addPhone(phoneNumber) {
  return new Promise((resolve, reject) => {
    window.jQuery.post(getApiPath() + '/account/phone', {
      harness_id: getHarnessId(),
      phone_number: phoneNumber,
      country_code: 1
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * verify phone code
 * @param code
 * @returns {Promise}
 */
function verifyPhone(code) {
  return new Promise((resolve, reject) => {
    window.jQuery.post(getApiPath() + '/account/phone/verify', {
      harness_id: getHarnessId(),
      verification_code: code
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}


/**
 * PATCH Toggle Phone Number Enable
 * @param enabled
 * @returns {Promise}
 */
function patchPhoneEnable(enabled) {
  return new Promise((resolve, reject) => {

    window.jQuery.ajax({
      url: getApiPath() + '/account/phone',
      type: 'PATCH',
      data: {
        jwt: getJwt(),
        enabled
      }
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * DEL Remove Phone Number
 * @returns {Promise}
 * {{url}}/account/phone?harness_id
 */
function deletePhone() {
  return new Promise((resolve, reject) => {
    window.jQuery.ajax({
      url: getApiPath() + '/account/phone?harness_id=' + getHarnessId(),
      type: 'DELETE',
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}


/**
 * send a reset link
 * @param email
 * @param charity_id
 * @returns {Promise}
 */
function resetPassword(email, charity_id) {
  return new Promise((resolve, reject) => {
    window.jQuery.post(getApiPath() + '/account/password/reset', {
      email,
      charity_id
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * set new password
 * @param token
 * @param password
 * @returns {Promise}
 */
function newPassword(token, password) {
  return new Promise((resolve, reject) => {
    window.jQuery.post(getApiPath() + '/account/password', {
      token,
      password
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * GET View All Public Campaigns
 */
function getAllPublicCampaigns(charity_id) {
  return apiRequest.doGetRequest(`/charity/${charity_id}/campaigns`);
}

/**
 * Get All Designations
 */
function getAllDesignations(charity_id) {
  return apiRequest.doGetRequest(`/charity/${charity_id}/designations`, {});
}

/**
 * Get All Categories
 */
function getAllCategories(charityId) {
  return apiRequest.doGetRequest(`/charity/${charityId}/categories`, {});
}

/**
 * Get a Category by ID
 */
function getCategoryById(charityId, categoryId) {
  return apiRequest.doGetRequest(`/charity/${charityId}/categories/${categoryId}`, {});
}


/**
 * GET View All Impact Posts
 */
function getAllImpactPosts(charity_id) {
  return apiRequest.doGetRequest(`/whitelabel/${charity_id}/impact-preview?limit=100`);
}

/**
 * GET View single Impact Post
 */
function getImpactPost(impact_id) {
  return apiRequest.doGetRequest(`/impact/${impact_id}`);
}

/**
 *GET View Donation History
 */
function getDonationHistory() {
  return apiRequest.doGetRequest('/account/donation-history', {jwt: getJwt()}, {
    resCallback: (res) => {
      res.sort((a, b) => {
        return (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime();
      });
      return res;
    }
  });
}

/**
 * Create donation confirmation code
 * Creates a single-use confirmation code that expires after 5 minutes
 * @param amount Donation Amount in CENTS. Automatically cast to INTEGER.
 * @returns {Promise}
 */
function createDonationConfirmationCode(amount) {
  return new Promise((resolve, reject) => {
    window.jQuery.post(getApiPath() + `/donate`, {
      jwt: getJwt(),
      amount_cents: amount,
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * Confirm donation
 * Convert/consume single-use confirmation token into a donation
 * @param confirmation_code Confirmation code generated by POST /donate
 * @param funding_source_id ID of funding source controlled by donor
 * @param campaign_id Campaign ID to attribute this donation to.
 * Don't include this parameter if the donor wants it to go to the AoGN
 * @param designation_id
 * @param honorInfo
 * @param cover_fees
 * @param noteInfo
 * @param flow_id
 * @param custom_fields_response_token
 * @returns {Promise}
 */
function confirmDonation(confirmation_code,
                         funding_source_id = false,
                         campaign_id = 0,
                         designation_id = 0,
                         honorInfo = null,
                         cover_fees = false,
                         noteInfo = null,
                         flow_id = null,
                         custom_fields_response_token = '') {
  return new Promise((resolve, reject) => {
    let params = {
      jwt: getJwt(),
      confirmation_code,
    };
    params['funding_source_id'] = funding_source_id;
    if (campaign_id) {
      params['campaign_id'] = campaign_id;
    }
    if (designation_id) {
      params['designation_id'] = designation_id;
    }
    if (cover_fees) {
      params['cover_fees'] = cover_fees;
    }
    if (honorInfo) {
      params['honor'] = honorInfo;
    }
    if (flow_id && Number(flow_id) !== 0) {
      params['flow_id'] = flow_id;
    }
    if (custom_fields_response_token) {
      params['custom_fields_response_token'] = custom_fields_response_token;
    }

    if (noteInfo) {
      params['note'] = {
        message: noteInfo.note,
        public: noteInfo.is_public,
      };
    }
    window.jQuery.post(getApiPath() + `/donate/confirm`, params)
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * POST Guest Donation
 *
 * @param stripe_token
 * @param charity_id
 * @param amount
 * @param email
 * @param first_name
 * @param last_name
 * @param campaign_id
 * @param designation_id
 * @param honorInfo
 * @param cover_fees
 * @param noteInfo
 * @param recaptcha_token
 * @param flow_id
 * @param custom_fields_response_token
 * @returns {Promise}
 */
function guestDonation(stripe_token,
                       charity_id,
                       amount,
                       email,
                       first_name,
                       last_name,
                       campaign_id = 0,
                       designation_id = 0,
                       honorInfo = null,
                       cover_fees = false,
                       noteInfo = null,
                       recaptcha_token = '',
                       flow_id = null,
                       custom_fields_response_token = '',
) {
  return new Promise((resolve, reject) => {
    let params = {
      stripe_token,
      charity_id,
      amount,
      email,
      first_name,
      last_name,
      recaptcha_token,
    };

    if (honorInfo) {
      params['honor'] = honorInfo;
    }
    if (noteInfo) {
      params['note'] = {
        message: noteInfo.note,
        public: noteInfo.is_public,
      };
    }
    if (cover_fees) {
      params['cover_fees'] = cover_fees;
    }
    if (campaign_id) {
      params['campaign_id'] = campaign_id;
    }
    if (designation_id) {
      params['designation_id'] = designation_id;
    }
    if (flow_id && Number(flow_id) !== 0) {
      params['flow_id'] = flow_id;
    }
    if (custom_fields_response_token) {
      params['custom_fields_response_token'] = custom_fields_response_token;
    }

    window.jQuery.post(getApiPath() + `/donate/guest`, params)
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * GET Calculate amount to cover donation fees
 * @param amount
 * @param charity_id
 * @param type
 * @returns {Promise}
 */
function getDonationFees(amount, charity_id, type = 'one-time') {
  if(type === 'monthly'){
    type = 'recurring';
  }
  if(type === 'guest'){
    type = 'one-time';
  }
  amount = Math.floor(amount);
  return apiRequest.doGetRequest('/donate/fee', {
    amount,
    charity_id,
    type
  }, {skipCache: true});
}


/**
 * POST Add donation note
 *
 * @param charge_id
 * @param note
 * @param isPublic
 * @param token
 * @returns {Promise}
 */
function addDonationNote(charge_id, note, isPublic, token = false) {
  return new Promise((resolve, reject) => {

    let params = {
      note,
      public: isPublic
    };

    if (token) {
      params['token'] = token;
    } else {
      params['harness_id'] = getHarnessId();
    }

    window.jQuery.post(getApiPath() + `/donation/${charge_id}/note`, params)
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * POST Add donation note
 *
 * @param charge_id
 * @param note
 * @param isPublic
 * @param token
 * @returns {Promise}
 */
function putDonationNote(charge_id, note, isPublic, token = false) {
  return new Promise((resolve, reject) => {

    let params = {
      note,
      public: isPublic
    };

    if (token) {
      params['token'] = token;
    } else {
      params['jwt'] = getJwt();
    }

    window.jQuery.ajax({
      type: 'PUT',
      url: getApiPath() + `/donation/${charge_id}/note`,
      data: params
    })
      .done(function (res) {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}


/**
 * GET View all public donations
 * @param charity_id
 * @param campaign_id
 * @returns {Promise}
 */
function getAllPublicDonations(charity_id, campaign_id) {
  if (campaign_id == 0) {
    campaign_id = -1;
  }

  return apiRequest.doGetRequest(`/charity/${charity_id}/donations`, {campaign_id}, {skipCache: true});
}

/**
 *  Get Donation Impact Amounts
 */
function getGetDonationImpactAmounts(charity_id) {
  return apiRequest.doGetRequest(`/charity/${charity_id}/donation-amounts`);
}


/**
 * POST Confirm photo uploaded to S3
 * {{url}}/account/profile-photo/upload
 *
 * @param upload_token
 * @returns {Promise}
 */
function confirmPhotoUploaded(upload_token) {
  return new Promise((resolve) => {
    window.jQuery.post(getApiPath() + `/account/profile-photo/upload`, {
      jwt: getJwt(),
      upload_token,
    }).always(() => {
      resolve('ok');
    });
  });
}

/**
 * put profile photo to AWS S3
 * @param uri
 * @param file
 * @returns {Promise}
 */
function putProfilePhoto(uri, file) {
  return new Promise((resolve, reject) => {
    window.jQuery.ajax({
      type: 'PUT',
      url: uri,
      contentType: 'binary/octet-stream',
      processData: false,
      data: file
    })
      .done((res) => {
        resolve(res);
      })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });
  });
}

/**
 * Get donation flow config
 * @returns {Promise}
 */
function getDonationFlow(charityId, flowId) {//flowId
  return apiRequest.doGetRequest(`/donate/${charityId}/flows/${flowId}`);
}

/**
 * POST Submit Custom Field Responses
 * {{url}}/donate/:charityId/flows/:flowId/custom-fields
 */
function submitCustomField(flowId, params, charityId) {
  return new Promise((resolve, reject) => {

    window.jQuery.ajax(getApiPath() + `/donate/${charityId}/flows/${flowId}/custom-fields`, {
      'data': JSON.stringify(params),
      'type': 'POST',
      'processData': false,
      'contentType': 'application/json'
    }).done(function (res) {
      resolve(res);
    })
      .fail((error) => {
        if (!checkErrorLogout(error)) reject(error);
      });

  });
}

/********************ApiRequest***************/
const apiRequest = new ApiRequest(getApiPath());

const userAccount = {
  /**
   * GET View Employment
   * @returns {Promise}
   */
  getEmployment() {
    return apiRequest.doGetRequest('/account/employment', {harness_id: getHarnessId()}, {skipCache: true});
  },
  /**
   * Uses the standard google maps geocoded data keys
   * @param employer_name
   * @param occupation
   * @param check_matching
   * @returns {Promise}
   */
  saveEmployment(employer_name, occupation, check_matching = null) {
    const params = {
      harness_id: getHarnessId(),
      employer_name,
      occupation
    };

    if (check_matching !== null) {
      params['check_matching'] = check_matching;
    }
    return apiRequest.doPostRequest('/account/employment', params);
  },
  /**
   * GET address
   * @returns {Promise}
   */
  getAddress() {
    return apiRequest.doGetRequest('/account/address', {jwt: getJwt()}, {skipCache: true});
  },
  /**
   * Uses the standard google maps geocoded data keys
   * @param country
   * @param administrative_area_level_1
   * @param locality
   * @param street_number
   * @param route
   * @param premise
   * @param postal_code
   * @param formatted_address
   * @param latitude
   * @param longitude
   * @returns {Promise}
   */
  saveAddress(country,
              administrative_area_level_1,
              locality,
              street_number,
              route,
              premise,
              postal_code,
              formatted_address,
              latitude = null,
              longitude = null,
  ) {
    return apiRequest.doPostRequest('/account/address', {
      jwt: getJwt(),
      country,
      administrative_area_level_1,
      locality,
      street_number,
      route,
      premise,
      postal_code,
      formatted_address,
      latitude,
      longitude
    });
  },
  /**
   * GET Gender IDs
   * Get the list of genders a donor may select
   */
  getAllGenders() {
    return apiRequest.doGetRequest(`/account/genders`);
  },

  /**
   * Get Upload URI and Token
   * This request will return a Pre-signed request URI, via upload_uri, that may be used to PUT a profile photo into an
   * AWS S3 Bucket. The signature will be valid for 5 minutes after being issued.
   * Once the file has been uploaded, the upload_token must be submitted to notify the API that the photo is ready for
   * processing. See the "Confirm photo uploaded to S3" request.
   *
   * @returns {Promise}
   */
  getUploadUriAndToken() {
    return apiRequest.doGetRequest(`/account/profile-photo/upload`, {jwt: getJwt()}, {skipCache: true})
  },
  /**
   * Get status of photo upload
   * @returns {Promise}
   */
  getPhotoStatus() {
    return apiRequest.doGetRequest(`/account/profile-photo/status`, {jwt: getJwt()}, {skipCache: true});
  },
  getDonorDetails() {
    return apiRequest.doGetRequest('/account/details', {jwt: getJwt()}, {skipCache: true});
  },
  /**
   * update account, save data after sign-up
   * @param firstName
   * @param lastName
   * @returns {Promise}
   */
  updateAccount(firstName, lastName) {
    return apiRequest.doPostRequest('/account/details', {
      jwt: getJwt(),
      firstName,
      lastName,
      enablePush: 1,
      enableFlash: 1,
      organizationId: 1,
      organizationCode: 1
    });
  },
  /**
   * Update Donor Details Individually
   * @param data
   * @returns {Promise}
   */
  patchAccount(data) {
    return apiRequest.doPatchRequest('/account/details?jwt=' + getJwt(), data);
  }
};

const roundupAccount = {
  /**
   * GET View all roundup accounts
   * @returns {Promise}
   */
  getAllRoundupAccounts() {

    return new Promise((resolve, reject) => {
      Promise.all([
        apiRequest.doGetRequest('/account/roundup', {jwt: getJwt()}, {skipCache: true}),
        apiRequest.doGetRequest('/card/roundup', {jwt: getJwt()}, {skipCache: true, returnParam: 'roundupAccounts'})
      ]).then(([r1, r2]) => {
        resolve([
          ...r1.map(item => ({...item, account_type: 'old'})),
          ...r2.map(item => ({...item, account_type: 'card-linked'})),
        ]);
      }).catch(error => reject(error))
    });

  },
  /**
   *
   * @param public_token - token generated by the Plaid Link javascript widget
   * @param account_id - from plaid response body
   * @param account_name - from plaid response body
   * @param account_type - from plaid response body
   * @param inst_id - from plaid response body
   * @param inst_name - from plaid response body
   * @param mask
   * @param campaign_id - optional
   * @param ancestor_id - optional; add id of deleted roundup account if this new account is "replacing" the old one
   * @param flow_id
   * @param custom_fields_response_token
   * @returns {Promise}
   */
  addRoundupAccount(public_token,
                    account_id,
                    account_name,
                    account_type,
                    inst_id,
                    inst_name,
                    mask,
                    campaign_id = false,
                    ancestor_id = false,
                    flow_id = false,
                    custom_fields_response_token = '',
  ) {

    let params = {
      jwt: getJwt(),
      public_token,
      account_id,
      account_name,
      account_type,
      inst_id,
      inst_name,
      mask
    };
    if (campaign_id) {
      params['campaign_id'] = campaign_id;
    }
    if (ancestor_id) {
      params['ancestor_id'] = ancestor_id;
    }
    if (flow_id && Number(flow_id) !== 0) {
      params['flow_id'] = flow_id;
    }
    if (custom_fields_response_token) {
      params['custom_fields_response_token'] = custom_fields_response_token;
    }

    return apiRequest.doPostRequest('/account/roundup', params);
  },

  /**
   * GET View pending roundups
   */
  getPendingRoundups(roundup_account_id, account_type = false) {
      if(account_type === "card-linked") {
        return apiRequest.doGetRequest(`/card/roundup/${roundup_account_id}/transactions`, {jwt: getJwt()}, {
          skipCache: true,
          returnParam: 'transactions',
          resCallback: (res) => res.map(r => ({
            ...r,
            account_type,
            calculated_roundup: r.calculated_roundup || r.amount / 100 || 0,
            charge_date: r.charge_date || r.transactedAt || null,
            transaction_id: r.transaction_id || r.id || null,
            transaction_name: r.transaction_name || r.formattedAmount || ''
          }))
        });
    }

    return apiRequest.doGetRequest(`/account/roundup/${roundup_account_id}/pending`, {jwt: getJwt()}, {skipCache: true});
  },
  /**
   * POST Change settings
   * @param roundup_account_id
   * @param max_cap
   * @param multiplier
   * @param rounding
   * @param campaign_id
   * @param account_type
   * @returns {Promise}
   */
  updateRoundupSettings(roundup_account_id, max_cap, multiplier, rounding, campaign_id = false, account_type = 'card-linked') {

    if(account_type === 'card-linked'){
      return cardLinkRoundupAccount.updateRoundupSettings(roundup_account_id, max_cap, rounding, campaign_id);
    }

    let params = {
      jwt: getJwt(),
      max_cap,
      multiplier,
      rounding
    };
    if (campaign_id !== false) {
      params['campaign_id'] = campaign_id;
    }
    return apiRequest.doPostRequest(`/account/roundup/${roundup_account_id}/settings`, params);
  },

  /**
   * DELETE Remove single roundup account
   * @returns {Promise}
   * {{url}}/account/roundup/{{roundup_account_id}}?jwt=
   */
  deleteRoundupAccount(roundup_account) {
    let roundup_account_id = false;

    if(typeof roundup_account === 'object' && roundup_account !== null){
      if(roundup_account.account_type === 'card-linked'){

        return apiRequest.doDeleteRequest(`/card/roundup/${roundup_account.id}`, {jwt: getJwt()});
      }
      else {
        roundup_account_id = roundup_account.accountId;
      }
    }
    else{
      roundup_account_id = roundup_account;
    }

    if(roundup_account_id) {
      return apiRequest.doDeleteRequest(`/account/roundup/${roundup_account_id}`, {jwt: getJwt()});
    }
  }

};

const cardLinkRoundupAccount = {
  /**
   * GET View a single roundup account
   * @returns {Promise}
   */
   getRoundupAccount(roundup_account_id) {
    return apiRequest.doGetRequest(`/card/roundup/${roundup_account_id}`, {jwt: getJwt()}, {skipCache: true});
  },

  /**
   *
   * @param card_id New Numeric ID of the card to enroll (This is the same as the numeric ID of the funding source corresponding to the card).
   * @param campaign_id - optional
   * @param flow_id
   * @param custom_fields_response_token
   * @returns {Promise}
   */
  addRoundupAccount(card_id,
                    campaign_id = false,
                    flow_id = false,
                    custom_fields_response_token = '',
  ) {
    let params = {
      jwt: getJwt(),
      card_id: card_id,
    };
    if (campaign_id) {
      params['campaign_id'] = campaign_id;
    }
    if (flow_id && Number(flow_id) !== 0) {
      params['flow_id'] = flow_id;
    }
    if (custom_fields_response_token) {
      params['custom_fields_response_token'] = custom_fields_response_token;
    }

    return apiRequest.doPostRequest('/card/roundup', params);
  },

  /**
   * POST Change settings
   * @param roundup_account_id
   * @param max_cap
   * @param rounding
   * @param campaign_id
   * @param designation_id
   * @returns {Promise}
   */
   updateRoundupSettings(roundup_account_id, max_cap, rounding, campaign_id = false, designation_id = false) {
    let params = {
      jwt: getJwt(),
      max_cap,
      rounding
    };
    if (campaign_id !== false) {
      params['campaign_id'] = campaign_id;
    }
    if (designation_id !== false) {
      params['designation_id'] = designation_id;
    }
    return apiRequest.doPutRequest(`/card/roundup/${roundup_account_id}`, params);
  },
};

const recurringDonation = {
  /**
   * Get all recurring donations
   * @returns {Promise}
   */
  getRecurringDonations() {
    return apiRequest.doGetRequest('/account/recurring', {jwt: getJwt()}, {
      skipCache: true,
      resCallback: (res) => {
        res.sort((a, b) => {
          return (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime();
        });
        return res;
      }
    });
  },
  /**
   * Add new recurring donation
   * @param funding_source_id
   * @param amount
   * @param campaign_id
   * @param designation_id
   * @param honorInfo
   * @param cover_fees
   * @param noteInfo
   * @param flow_id
   * @param custom_fields_response_token
   * @returns {Promise}
   */
  addRecurringDonation(funding_source_id,
                       amount,
                       campaign_id = 0,
                       designation_id = 0,
                       honorInfo = null,
                       cover_fees = false,
                       noteInfo = null,
                       flow_id = null,
                       custom_fields_response_token = '') {
    let params = {
      jwt: getJwt(),
      amount,
      funding_source_id
    };
    if (campaign_id) {
      params['campaign_id'] = campaign_id;
    }
    if (designation_id) {
      params['designation_id'] = designation_id;
    }
    if (cover_fees) {
      params['cover_fees'] = cover_fees;
    }
    if (honorInfo) {
      params['honor'] = honorInfo;
    }
    if (flow_id && Number(flow_id) !== 0) {
      params['flow_id'] = flow_id;
    }
    if (custom_fields_response_token) {
      params['custom_fields_response_token'] = custom_fields_response_token;
    }
    if (noteInfo) {
      params['note'] = {
        message: noteInfo.note,
        public: noteInfo.is_public,
      };
    }

    return apiRequest.doPostRequest(`/account/recurring`, params);
  },
  /**
   * DELETE Delete single recurring donation
   * @param subscription_id
   * @returns {Promise}
   */
  deleteRecurringDonation(subscription_id) {
    return apiRequest.doDeleteRequest(`/account/recurring/${subscription_id}`, {jwt: getJwt()});
  },
  /**
   * POST Update target campaign
   * @param subscription_id
   * @param campaign_id
   * @returns {Promise}
   */
  updateRecurringCampaign(subscription_id, campaign_id) {
    return apiRequest.doPostRequest(`/account/recurring/${subscription_id}/campaign`, {
      jwt: getJwt(),
      campaign_id,
    });
  },
  /**
   * POST Update funding source
   */
  updateRecurringFundingSource(subscription_id, funding_source_id) {
    return apiRequest.doPostRequest(`/account/recurring/${subscription_id}/funding`, {
      jwt: getJwt(),
      funding_source_id,
    });
  },
  /**
   * POST Set funding source
   */
  setFundingSource(roundup_account_id, funding_source_id) {
    return apiRequest.doPostRequest(`/account/roundup/${roundup_account_id}/funding`, {
      jwt: getJwt(),
      funding_source_id
    });
  }

};

const fundingSource = {
  /**
   * GET View All funding sources
   */
  getAllFundingSources() {
    return apiRequest.doGetRequest('/account/funding', {jwt: getJwt()}, {skipCache: true});
  },
  /**
   * POST Add new funding source
   * @param stripe_token
   * @param recaptcha_token
   * @returns {Promise}
   */
  addFundingSource(stripe_token, recaptcha_token = '') {
    return apiRequest.doPostRequest('/account/funding', {
      jwt: getJwt(),
      stripe_token,
      recaptcha_token
    });
  },
  /**
   * DELETE single funding source
   * @param funding_source_id
   * @returns {Promise}
   */
  deleteFundingSource(funding_source_id) {
    return apiRequest.doDeleteRequest(`/account/funding/${funding_source_id}`, {jwt: getJwt()});
  },
};

export {
  getDonationFlow,
  submitCustomField,

  getJwt,
  getWhitelabelReq,

  login,
  signUp,
  resetPassword,
  newPassword,

  /// userAccount
  userAccount,
  putProfilePhoto,
  confirmPhotoUploaded,

  addPhone,
  patchPhoneEnable,
  deletePhone,
  verifyPhone,
  /// end userAccount

  roundupAccount,
  cardLinkRoundupAccount,
  recurringDonation,
  fundingSource,

  getAllPublicCampaigns,
  getAllDesignations,

  getDonationHistory,
  getDonationFees,

  createDonationConfirmationCode,
  confirmDonation,
  guestDonation,
  addDonationNote,
  putDonationNote,

  getAllPublicDonations,

  getGetDonationImpactAmounts,

  getAllImpactPosts,
  getImpactPost,

  getAllCategories,
  getCategoryById,

  getHarnessId
}
