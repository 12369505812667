<template>
  <form method="post" @submit.prevent="submitSignUp">
    <div class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'user-circle']"/></i>
      <input type="text"
             class="input-text with-border"
             name="full-name"
             id="full-name"
             v-model="fullName"
             title="Name Surname"
             pattern=".+\s.+"
             placeholder="Full name"
             required/>
    </div>

    <div class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
      <input type="email"
             class="input-text with-border"
             name="emailaddress-register"
             placeholder="Email Address"
             v-model="email"
             required/>
    </div>

    <div v-if="harness_fields.phone_number.enabled"
         class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'phone-alt']" /></i>
      <masked-input
          type="tel"
          :required="harness_fields.phone_number.required"
          inputmode="numeric"
          class="input-text with-border"
          pattern="[0-9]{3}-[0-9]{3}-(([0-9]{3}\s)|[0-9]{4})"
          name="phone-register"
          v-model="phoneNumber"
          :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
          :guide="true"
          :placeholderChar="'\u2000'"
          placeholder="Phone Number"
      />
    </div>
    <div v-if="harness_fields.address.enabled"
         class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'map-marker-alt']" /></i>
      <vue-google-autocomplete
          v-address-google
          class="input-text with-border"
          :id="`ma-${Math.random().toString(36).substring(2)}`"
          :name="`ma-${Math.random().toString(36).substring(2)}`"
          :required="harness_fields.address.required"
          autocomplete="new-password"
          aria-autocomplete="none"
          autofill="off"
          placeholder="Add‌ress"
          country="us"
          v-on:placechanged="getAddressData"
      ></vue-google-autocomplete>
    </div>

    <div class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'lock-alt']" /></i>
      <input type="password"
             class="input-text with-border"
             name="password-register"
             v-model="password"
             minlength="6"
             placeholder="Password"
             required/>
    </div>

    <slide-up-down class="slide-up-down" :class="{'is-active': signUpIsError}" :active="signUpIsError">
      <div class="notification error">
        <p>{{signUpError}}</p>
        <a href="#" v-on:click.prevent="signUpIsError = false" class="close"></a>
      </div>
    </slide-up-down>



    <button class="margin-top-0 button full-width button-sliding-icon ripple-effect"
            type="submit"
            :class="{ disabled: isCreateAccountProcess }"
            :disabled="isCreateAccountProcess"
    ><span v-if="isCreateAccountProcess" class="loading-state">
      <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
      Create Account <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
    </button>

    <!-- Powered by -->
    <div class="powered-by-separator" style="margin-bottom: -20px">
      <span>I agree to the
        <router-link :to="{name: 'Terms'}"
                     target="_blank"
                     style="font-weight:bold; color:#677788;">Terms & Conditions</router-link>
      </span>
    </div>

  </form>
</template>

<script>
  import {login, signUp, addPhone, userAccount} from '../../utils/api'
  import MaskedInput from 'vue-text-mask'
  import SlideUpDown from 'vue-slide-up-down'
  import recaptchaMixin from '../Donations/recaptcha-mixin'
  import registerMixin from '../Donations/register-mixin'
  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  export default {
    name: 'RegisterForm',
    props: {
      charityInfo: null,
      initialEmail: {
        type: String,
        'default': ''
      },
      harness_fields: {
        type: null,
        'default': () =>
          ({
            "phone_number": {
              "enabled": true,
              "required": false
            },
            "address": {
              "enabled": true,
              "required": false
            }
          })
      }
    },
    mixins: [recaptchaMixin, registerMixin],
    components: {
      SlideUpDown,
      MaskedInput,
      VueGoogleAutocomplete,
    },
    created() {
      this.address = this.addressInit;
    },
    methods: {
      onVerifyInvisible(response) {
        this.captchaKey = response;
        this.submitSignUp();
      },
      submitSignUp() {
        if (this.captchaKey === '') {
          this.$emit('recaptcha-execute');
          return;
        }

        this.isCreateAccountProcess = true;
        signUp(this.email, this.password, this.charityInfo.charity_id, this.captchaKey)
          .then(() => {

            this.$tracking.track(this.$tracking.EVENTS.COMPLETE_REGISTRATION, {
              'content_name': 'email',
            });

            //get JWT
            login(this.email, this.password, this.charityInfo.charity_id)
              .then(res => {
                window.localStorage.jwt = res.token;
                userAccount.updateAccount(this.firstName, this.lastName).then(() => {

                  if (this.formatted_address) {
                    this.saveAddress();
                  }

                  if (this.phoneNumber) {
                    addPhone(this.phoneNumber).then(() => {
                      this.confirmPhone = true;
                      this.$emit('confirm-phone', this.phoneNumber);
                      this.isCreateAccountProcess = false;
                    }).catch(err => {
                      //show error and skip this step
                      alert(err.responseJSON && err.responseJSON.error || 'Can not send confirmation code to the phone number');
                      this.isCreateAccountProcess = false;
                      this.$emit('update-donor-details');
                    });
                  }
                  else {
                    this.isCreateAccountProcess = false;
                    this.$emit('update-donor-details');
                  }
                });
              });
          })
          .catch(err => {
            this.signUpError = err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
            this.signUpIsError = true;
            this.isCreateAccountProcess = false;
            this.$emit('recaptcha-reset');
          });
      },
    },
    computed: {
      firstName () {
        return this.fullName.split(' ')[0];
      },
      lastName () {
        return this.fullName.split(' ').slice(1).join(' ');
      },
    },
    watch: {
      'email'() {
        this.signUpIsError = false;
      },
      'password'() {
        this.signUpIsError = false;
      },
      'fullName'() {
        this.signUpIsError = false;
      },
      'phoneNumber'() {
        this.signUpIsError = false;
      },
      'initialEmail'(){
        if(this.initialEmail){
          this.email = this.initialEmail;
        }
      }
    },
    data() {
      return {
        email: '',
        password: '',
        fullName: '',
        isCreateAccountProcess: false,
        signUpIsError: false,
        signUpError: '',
        phoneNumber: ''
      };
    },
  }
</script>