<template>
  <form method="post"
        @submit.prevent="submitLogin"
  >
    <div class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
      <input type="email"
             class="input-text with-border"
             name="email"
             placeholder="Email Address"
             required
             v-model="loginEmail"
      />
    </div>

    <div class="input-with-icon-left">
      <i><font-awesome-icon :icon="['far', 'lock-alt']" /></i>
      <input type="password"
             class="input-text with-border"
             name="password"
             placeholder="Password"
             required
             v-model="loginPassword"
      />
    </div>

    <slide-up-down class="slide-up-down" :class="{'is-active': loginIsError}" :active="loginIsError">
      <div class="notification error">
        <p>{{loginError}}</p>
        <a href="#" v-on:click.prevent="loginIsError = false" class="close"></a>
      </div>
    </slide-up-down>

    <router-link :to="{name: 'ForgotPassword', query: loginEmail ? { email: loginEmail } : null }"
                 class="forgot-password">Forgot Password?
    </router-link>

    <button class="margin-top-15 button full-width button-sliding-icon ripple-effect"
            type="submit"
            :class="{ disabled: isLoginProcess }"
            :disabled="isLoginProcess"
    ><span v-if="isLoginProcess" class="loading-state">
      <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
      Log In <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
    </button>

  </form>
</template>

<script>
  import {login} from '../../utils/api'
  import SlideUpDown from 'vue-slide-up-down'

  export default {
    name: 'LoginForm',
    props: {
      charityInfo: null,
      initialEmail: {
        type: String,
        'default': ''
      },
    },
    components: {
      SlideUpDown,
    },
    mounted() {
      if (this.initialEmail) {
        this.loginEmail = this.initialEmail;
      }
    },
    methods: {
      submitLogin() {
        const self = this;
        self.isLoginProcess = true;
        login(this.loginEmail, this.loginPassword, this.charityInfo.charity_id)
          .then(res => {

            this.$tracking.track(this.$tracking.EVENTS.Login, {
              'content_name': 'email',
            }, true, false);

            window.localStorage.jwt = res.token;

            // eslint-disable-next-line
            analytics.track("Logged In", {
              email: this.loginEmail,
              organization_name: this.charityInfo.charity_name,
              charity_id: this.charityInfo.charity_id,
            });

            self.loginEmail = '';
            self.loginPassword = '';
            self.$emit('update-donor-details');

            self.isLoginProcess = false;
          })
          .catch(err => {
            this.loginError = err.responseJSON && err.responseJSON.error || 'Wrong credentials';
            this.loginIsError = true;
            self.isLoginProcess = false;
          });
      },
    },
    watch: {
      'loginEmail': function () {
        this.loginIsError = false;
      },
      'loginPassword': function () {
        this.loginIsError = false;
      },
      'initialEmail'() {
        if (this.initialEmail) {
          this.loginEmail = this.initialEmail;
        }
      }

    },
    data() {
      return {
        loginEmail: '',
        loginPassword: '',
        isLoginProcess: false,
        loginIsError: false,
        loginError: '',

      };
    },
  }
</script>