export default class ApiRequest {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  static checkErrorLogout(error) {
    if (error && error.responseJSON && (
      error.responseJSON.error === 'Incorrect harness_id' || error.responseJSON.error === 'Invalid JWT')
    ) {
      localStorage.clear();
      window.location.reload();
      return true;
    }
    return false
  }

  doGetRequest(apiPath, params = {}, {returnParam = false, skipCache = false, resCallback = null} = {}) {
    return new Promise((resolve, reject) => {
      if (skipCache) {
        // params['timestamp'] = new Date().valueOf();
        params['timestamp'] = Math.round(new Date().getTime() / 1000 / 5) * 5;
      }

      window.jQuery.get(`${this.apiUrl}${apiPath}`, params)
        .done((res) => {
          if (returnParam) {
            res = res[returnParam]
          }
          if (typeof resCallback === "function") {
            res = resCallback(res);
          }
          resolve(res);
        })
        .fail((error) => {
          if (!ApiRequest.checkErrorLogout(error)) reject(error);
        });
    });
  }

  doPostRequest(apiPath, params = {}, {returnParam = false} = {}) {

    return new Promise((resolve, reject) => {
      window.jQuery.post(`${this.apiUrl}${apiPath}`, params)
        .done(res => {
          if (returnParam) {
            resolve(res[returnParam]);
          } else {
            resolve(res);
          }
        })
        .fail((error) => {
          if (!ApiRequest.checkErrorLogout(error)) reject(error);
        });
    });
  }

  doPatchRequest(apiPath, data = {}, {returnParam = false} = {}) {
    return new Promise((resolve, reject) => {

      window.jQuery.ajax({
        url: `${this.apiUrl}${apiPath}`,
        type: 'PATCH',
        data
      })
        .done(res => {
          if (returnParam) {
            resolve(res[returnParam]);
          } else {
            resolve(res);
          }
        })
        .fail((error) => {
          if (!ApiRequest.checkErrorLogout(error)) reject(error);
        });
    });
  }

  doPutRequest(apiPath, data = {}, {returnParam = false} = {}) {
    return new Promise((resolve, reject) => {

      window.jQuery.ajax({
        url: `${this.apiUrl}${apiPath}`,
        type: 'PUT',
        data
      })
        .done(res => {
          if (returnParam) {
            resolve(res[returnParam]);
          } else {
            resolve(res);
          }
        })
        .fail((error) => {
          if (!ApiRequest.checkErrorLogout(error)) reject(error);
        });
    });
  }

  doDeleteRequest(apiPath, data = {}, {returnParam = false} = {}) {

    return new Promise((resolve, reject) => {

      window.jQuery.ajax({
        url: `${this.apiUrl}${apiPath}`,
        type: 'DELETE',
        data
      })
        .done(res => {
          if (returnParam) {
            resolve(res[returnParam]);
          } else {
            resolve(res);
          }
        })
        .fail((error) => {
          if (!ApiRequest.checkErrorLogout(error)) reject(error);
        });
    });
  }
}
