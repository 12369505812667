<template>

  <div class="Modal mfp-hide" ref="modal">
    <!--<div class="Modal__inner">-->
    <slot></slot>
    <!--</div>-->
  </div>

</template>


<script>
  import _ from 'underscore'
  import $ from 'jquery'
  import 'magnific-popup'
  import {initTabs} from '../../utils/utils';

  export default {
    name: 'magnific-popup-modal',
    props: {
      show: {
        type: Boolean,
        'default': false
      },
      config: {
        type: Object,
        'default': {}
      }
    },
    data() {
      return {
        visible: this.show
      }
    },
    mounted() {
      this[this.visible ? 'open' : 'close']()
    },
    methods: {
      /**
       * Opens the modal
       * @param {object} options Last chance to define options on this call to Magnific Popup's open() method
       */
      open: function (options) {
        if (!!$.magnificPopup.instance.isOpen && this.visible) {
          return
        }
        let root = this;

        let config = (_.extend({
          // magnific defaults
          disableOn: null,
          mainClass: '',
          preloader: true,
          focus: '',
          closeOnContentClick: false,
          closeOnBgClick: false,
          closeBtnInside: false,
          showCloseBtn: true,
          enableEscapeKey: true,
          modal: false,
          alignTop: false,
          index: null,
          fixedContentPos: 'auto',
          fixedBgPos: 'auto',
          overflowY: 'auto',
          removalDelay: 0,
          closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-angle-left">' +
              '<path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>' +
              '</svg></button>',
          autoFocusLast: true
        }, this.config));

        config = _.extend(
          config,
          {
            items: {
              src: $(this.$refs.modal),
              type: 'inline'
            },
            callbacks: {
              open: function () {
                root.visible = true;
                root.$emit('open')
              },
              close: root.close
            }
          },
          options || {});

        $.magnificPopup.open(config);
        initTabs();
      },
      /**
       * Closes the modal
       */
      close: function () {
        if (!$.magnificPopup.instance.isOpen && !this.visible) {
          return
        }
        this.visible = false;
        $.magnificPopup.close();
        this.$emit('close')
      },
      /**
       * Toggles modal open/closed
       */
      toggle: function () {
        this[this.visible ? 'close' : 'open']()
      }
    }
  }
</script>
