import Vue from 'vue';
import {getDonationHistory} from '../../utils/api'

const moment = require('moment');

const state = {
  allDonations: [],
  donationHistoryLoaded: false,
  lastUpdate: null
};

// actions
const actions = {
  updateDonations({commit, state}, force = false) {
    return new Promise((resolve) => {

      //cache for 2 minutes
      if (state.lastUpdate === null || force || moment().diff(state.lastUpdate, 'seconds') > 60 * 2) {
        getDonationHistory().then(donations => {
          commit('setDonations', donations);
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  },
};

// mutations
const mutations = {
  setDonations(state, allDonations) {
    Vue.set(state, 'lastUpdate', moment());
    Vue.set(state, 'donationHistoryLoaded', true);
    Vue.set(state, 'allDonations', allDonations);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};