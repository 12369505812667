import './assets/css/style.css'
import './assets/css/colors/default.css'
import './assets/scss/styles.scss'
import './assets/scss/emoji-picker.scss'
import './assets/scss/flow.scss'
import './components/Donations/invoice.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import config from './config.json';
import {formatMoney, inIframe} from './utils/utils';
import store from './store'
import Ripple from 'vue-ripple-directive'
import LaunchDarkly from './utils/launch-darkly';

import $ from 'jquery'
import './icons'
import './plugins/gtm';

import 'url-search-params-polyfill';
import SlideUpDown from 'vue-slide-up-down'

if (window.document.documentMode) {
  SlideUpDown.props.duration.type = null;
}

import DonationTypes from './store/modules/donation-types'

Vue.mixin({
  created() {
    this.DonationTypes = DonationTypes;
  }
});

import VueConfetti from 'vue-confetti/src/index'
Vue.use(VueConfetti);

import VueRouter from 'vue-router';

const originalReplace = router.replace
router.replace = function replace(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }

  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

import Tracking from './plugins/tracking';
Vue.use(Tracking, {router});


const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
  onDone: (el) => history.pushState(null, null, '#' + el.id)
});

const moment = require('moment');

Ripple.color = 'rgba(255, 255, 255, 0.1)';
Vue.directive('ripple', Ripple);

Ripple.color = 'rgba(0, 0, 0, 0.03)';
Vue.directive('ripple-dark', Ripple);

Vue.filter('formatMoney', (amount, n = 0, x = 3) => {
  return formatMoney(amount, n, x);
});

Vue.filter('dateFormat', function (date, format, fromFormat = false) {
  if (date === null) {
    return moment().format(format);
  }
  if (fromFormat) {
    return moment(date, fromFormat).format(format);
  }
  return moment(date).format(format);
});

Vue.directive('select-picker', {
  inserted(el) {
    $(el).selectpicker();
  }
});

Vue.directive('address-google', {
  inserted(el) {
    $(el).attr("autocomplete", "off");

    // el.setAttribute( "autocomplete", "fake-name-disable-autofill" );
  }
});

Vue.directive('coin-animation', {
  inserted(el) {
    if ($('.heart').length) {
      $('.heart').remove();
    }
    $(el).append('<div class="heart sidetoside-animate"></div>');
    $('.heart').animate({bottom: "+=1200"}, 8000);
  }
});

Vue.directive('sliding-button', {
  inserted(el) {
    $(el).css('width', $(el).outerWidth() + 30);
  }
});

Vue.directive('interactive-effect', {
  inserted(el) {
    $(el).on('click', function () {
      $(el).addClass('interactive-effect');
      setTimeout(() => $(el).removeClass('interactive-effect'), 400);
    });
  }
});

Vue.filter('dateFromNow', function (date, fromFormat = false) {
  if (fromFormat) {
    return moment(date, fromFormat).fromNow();
  }
  return moment(date).fromNow();
});

Vue.filter('correctLink', function (link) {
  if (link.split('//').length < 2) {
    return '//' + link;
  }
  return link;
});

Vue.filter('dateToNow', function (date, fromFormat = false, withoutPrefix = true) {
  if (fromFormat) {
    return moment(date, fromFormat).toNow(withoutPrefix);
  }
  return moment(date).fromNow(withoutPrefix);
});

import VueTippy from 'vue-tippy'

Vue.use(VueTippy, {
  delay: 100,
  arrow: true,
  arrowType: 'sharp',
  size: 'regular',
  duration: 200,
  animation: 'shift-away',

  animateFill: true,
  theme: 'dark',

  // How far the tooltip is from its reference element in pixels
  distance: 10,
});

const launchDarkly = LaunchDarkly.deferInitialization(config.launchdarkly.sdk_key);

Vue.config.productionTip = false;
Vue.prototype.$launchDarkly = launchDarkly; 

router.beforeEach((to, from, next) => {

  //redirect for old links
  if (to.meta.flowRedirect) {
    next({
      name: 'Donate',
      query: {
        ...to.meta.flowRedirect,
        ...(to.query || {})
      }
    })
  } else if (to.name === 'DonationFlow') {
    next({
      name: 'Donate',
      query: {
        'checkout': to.params.formId,
        ...(to.query || {}),
      }
    })
  } else if (to.name === 'DonationProceed') {
    let queryParams = {...to.query};
    if (queryParams.type && !queryParams['selected-method']) {
      queryParams['selected-method'] = queryParams.type;
      delete queryParams.type
    }

    next({
      name: 'Donate',
      query: queryParams
    })
  } else {

    $.magnificPopup.close();
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

    if (requiresAuth && !window.localStorage.getItem('jwt')) {
      next({name: 'DonationFlow', query: {auth: to.fullPath}});
    } else if (requiresNoAuth && window.localStorage.getItem('jwt')) {
      next(to.meta.authLink || {name: 'DonationFlow'});
    }
    next();
  }
});

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  created() {
    this.inIframe = inIframe();
  },
  methods: {
    screenNames(name){
      switch (name){
        case 'amount':
          if(this.donationType === 'roundup'){
            return 'get_started';
          }
          return 'donation_options';
        case 'smart-ask':
          return 'smart_ask';
        case 'register':
          return 'login_register';
        case 'custom-fields':
          return 'custom_fields';
        case 'payment':
          return 'payment_info';
        case 'thanks-page':
          return 'donation_confirmation';


        case 'round-ups':
          return 'connect_card';
        default:
          return name;
      }
    },
  },
  watch: {
    'donationScreen'(n) {
      if (this.donationTrackingScreen !== this.screenNames(n)) {
        this.donationTrackingScreen = this.screenNames(n);
      }
    },
    'donationTrackingScreen'(n, o) {
      if (n !== o) {
        this.$tracking.track(this.$tracking.EVENTS.SCREEN_TRANSITION, {
          'from': o,
          'to': n,
        });
      }
    },
    'donationType'() {
      if (this.donationTrackingScreen !== this.screenNames(this.donationScreen)) {
        this.donationTrackingScreen = this.screenNames(this.donationScreen);
      }

    },
  },
  data() {
    return {
      config: config,
      inIframe: false,
      donationScreen: null,
      donationType: null,
      donationTrackingScreen: null,
      popupConfig: {
        type: 'inline',

        fixedContentPos: false,
        fixedBgPos: true,

        closeBtnInside: true,
        preloader: false,

        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in'
      }
    };
  },
});
