import $ from 'jquery'
import config from '../config.json';
import Snackbar from "node-snackbar";

/**
 * set active account
 * @param accountId
 */
function setActiveAccount(accountId) {
  try {
    window.localStorage.setItem("activeAccountId", accountId);
  } catch (e) {
    return null;
  }
  window.localStorage.removeItem('toRoundups');

}


/**
 * get active account
 */
function getActiveAccount() {
  return window.localStorage.getItem("activeAccountId");
}

function shorten(str, maxLen, separator = ' ', end = '…') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + end;
}


function formatMoney(val, n, x) {
  if (val % 1 !== 0 && n < 2) {
    n = 2;
  }
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return parseFloat(val).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
}

function initTabs() {

  const $tabsNav = $('.popup-tabs-nav'),
    $tabsNavLis = $tabsNav.children('li');

  $tabsNav.each(function () {
    const $this = $(this);

    $this.next().children('.popup-tab-content').stop(true, true).hide().first().show();
    $this.children('li').first().addClass('active').stop(true, true).show();
  });

  $tabsNavLis.on('click', function (e) {
    const $this = $(this);

    e.preventDefault();
    if($this.find('a.link-disabled').length){
      return false;
    }

    if($this.siblings().length === 0 ){
      return false;
    }

    $this.siblings().removeClass('active').end().addClass('active');

    $this.parent().next().children('.popup-tab-content').stop(true, true).hide()
      .siblings($this.find('a').attr('href')).fadeIn();

  });

  const hash = window.location.hash;
  const anchor = $('.tabs-nav a[href="' + hash + '"]');
  if (anchor.length === 0) {
    $(".popup-tabs-nav li:first").addClass("active").show(); //Activate first tab
    $(".popup-tab-content:first").show(); //Show first tab content
  } else {
    anchor.parent('li').click();
  }

  // Link to Register Tab
  $('.register-tab').on('click', function (event) {
    event.preventDefault();
    $(".popup-tab-content").hide();
    $("#register.popup-tab-content").show();
    $("body").find('.popup-tabs-nav a[href="#register"]').parent("li").click();
  });
  // Link to Register Tab
  $('.login-tab').on('click', function (event) {
    event.preventDefault();
    $(".popup-tab-content").hide();
    $("#login.popup-tab-content").show();
    $("body").find('.popup-tabs-nav a[href="#login"]').parent("li").click();
  });

  // Disable tabs if there's only one tab
  $('.popup-tabs-nav').each(function () {
    var listCount = $(this).find("li").length;
    if (listCount < 2) {
      $(this).css({
        'pointer-events': 'none'
      });
    }
  });
}

function getDefaultCampaign(image, description = 'Donations will go to the area needed most') {
  return {
    id: 0,
    name: 'Area of Greatest Need',
    description: description,
    image: image
  };
}

function stickyHeader() {

  $(window).on('scroll load', function () {

    // if ($(window).width() < '1099') {
    //   $("#header-container").removeClass("cloned");
    // }

    // if ($(window).width() > '1099' || true) {

      // CSS adjustment
      $("#header-container").css({
        position: 'fixed',
      });
      $("#wrapper").addClass('fixed-header-padding');

      var headerOffset = $("#header-container").height();

      if ($(window).scrollTop() >= headerOffset) {
        $("#header-container").addClass('cloned');
        $(".wrapper-with-transparent-header #header-container").addClass('cloned').removeClass("transparent-header unsticky");
      } else {
        $("#header-container").removeClass("cloned");
        $(".wrapper-with-transparent-header #header-container").addClass('transparent-header unsticky').removeClass("cloned");
      }

      // Sticky Logo
      var transparentLogo = $('#header-container #logo img').attr('data-transparent-logo');
      var stickyLogo = $('#header-container #logo img').attr('data-sticky-logo');

      if ($('.wrapper-with-transparent-header #header-container').hasClass('cloned')) {
        $("#header-container.cloned #logo img").attr("src", stickyLogo);
      }

      if ($('.wrapper-with-transparent-header #header-container').hasClass('transparent-header')) {
        $("#header-container #logo img").attr("src", transparentLogo);
      }

      $(window).on('load resize', function () {
        var headerOffset = $("#header-container").height();
        $("#wrapper").css({'padding-top': headerOffset});
      });
    // }
  });
}

function getApiPath() {
  return `${config.harness.scheme}://${config.harness.url}/${config.harness.api_version}`
}

/**
 * get domain
 * @returns {string}
 */
function getDomain() {
  const subdomain = window.location.host.split('.')[0];

  if (subdomain.search('localhost') !== -1) {
    return 'clay';
    // return 'bsa';
    // return 'directrelief';
    // return '5c516fe7b5c853e0a620';//Equality California
    // return 'alsa';
    // return 'harp';
    // return 'lander';
    // return 'beatnb';
    // return 'academyatthelakes';//without campaigns
    // return 'helpingpeople';
    // return 'dream';
  }
  if (subdomain.search('192') !== -1) {
    return 'clay';
  }
  if (subdomain === 'webapp-staging-3') {
    return 'clay';
  }
  return subdomain;
}


function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function interactiveEffect() {
  $(".switch, .radio").each(function () {
    const intElem = $(this);
    intElem.on('click', function () {
      intElem.addClass('interactive-effect');
      setTimeout(function () {
        intElem.removeClass('interactive-effect');
      }, 400);
    });
  });
}

function showNotification(text, bgColor = '#383838') {
  Snackbar.show({
    text: text,
    pos: 'top-center',
    customClass: 'copied-block',
    showAction: false,
    textColor: '#fff',
    duration: 3000,
    backgroundColor: bgColor,
  });
}

function stripHtml(html)
{
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function getHarnessGivingURL() {
  return `${config.harnessgiving.scheme}://${getDomain()}.${config.harnessgiving.url}/`
}

export {
  showNotification,
  formatMoney,
  initTabs,
  getApiPath,
  getDomain,
  stickyHeader,
  setActiveAccount,
  getActiveAccount,
  getDefaultCampaign,
  getHarnessGivingURL,

  inIframe,
  interactiveEffect,
  shorten,
  stripHtml,
};
