import {hex2rgb} from '@csstools/convert-colors';


function cssColors(primaryColor, secondaryColor) {

  let [pRed, pGreen, pBlue] = hex2rgb(primaryColor).map(c => c / 100 * 255).map(Math.round);

  return (`
.add-card-wrap input:active{
  background-color: ${primaryColor}
}
.add-card-wrap .input.focused + label{
  color: ${primaryColor}
}
.add-card-wrap .input.focused + label + .baseline{
  background-color: ${primaryColor}
}
  
.numbered.color ol > li::before {
  border: 1px solid ${primaryColor};
  color: ${primaryColor};
}

.numbered.color.filled ol > li::before {
  border: 1px solid ${primaryColor};
  background-color: ${primaryColor};
}

.radio input[type="radio"]:checked + label .radio-label {
  background-color: ${primaryColor};
  border-color: ${primaryColor};
}

.checkbox input:checked + label span.checkbox-icon {
  background-color: ${primaryColor};
  border-color: ${primaryColor};
}

.slider-handle {
  border: 2px solid ${primaryColor};
}

.infoBox-close:hover {
  color: #fff;
  background-color: ${primaryColor};
}

.message-bubble.me .message-text:before {
  border-left: 6px solid ${primaryColor};
}

body .vdp-datepicker__calendar .cell.selected,
body .vdp-datepicker__calendar .cell.selected:hover{
  background-color: ${primaryColor};
  color: white;
}
body .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, 
body .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, 
body .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid ${primaryColor};
}

a,
.header-widget .log-in-button:hover i,
.notification-text span.color,
.header-notifications-content .notification-text span.color,
ul.user-menu-small-nav li a:hover,
ul.user-menu-small-nav li a:hover i,
#breadcrumbs ul li a:hover,
#breadcrumbs.white ul li a:hover,
.single-page-header .header-details li a:hover,
.blog-post-content h3 a:hover,
#posts-nav li a:hover,
.task-tags span,
.freelancer-detail-item a:hover,
.share-buttons-content span strong,
.keyword,
.category-box-icon,
.boxed-widget.summary li.total-costs span,
.single-counter h3,
.popup-tabs-nav li.active a,
.dialog-with-tabs .forgot-password:hover,
.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.bootstrap-select .dropdown-menu li.selected a span.check-mark:before,
.bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before,
.boxed-list-headline h3 i,
.boxed-list-item .item-details .detail-item a:hover,
.account-indicators .indicator strong,
.bootstrap-select.btn-group .dropdown-menu li.active small,
.help-icon,
.goal-indicators .indicator strong,
.comments-amount,
.primary-color,
.header-widget .log-in-button:hover,
.salary-box .salary-amount,
.job-listing h4.job-listing-company,
.header-widget .log-in-button:hover i {
  color: ${primaryColor};
}

.primary-color-force{
  color: ${primaryColor} !important;
}

#backtotop a,
.header-notifications-trigger span,
a.header-notifications-button,
.footer-links ul li a span:before,
.newsletter button,
.pagination ul li a.current-page,
a.blog-post-info:hover,
#posts-nav li a:hover span,
.comment-by a.reply:hover,
.contact-address-headline:after,
.job-listing.with-apply-button:hover .list-apply-button,
.letters-list a.current,
span.button,
button.button,
input[type="button"],
input[type="submit"],
a.button,
mark.color,
table.basic-table th,
.copy-url .copy-url-button,
.keyword-input-container .keyword-input-button,
.tags-container input[type="checkbox"]:checked + label,
input:checked + .switch-button,
.radio input[type="radio"] + label .radio-label:after,
.payment-tab-trigger > input:checked ~ label::after,
.breathing-icon,
.icon-box-check,
#sign-in-dialog .mfp-close:hover,
#small-dialog-1 .mfp-close:hover,
#small-dialog-2 .mfp-close:hover,
#small-dialog-3 .mfp-close:hover,
#small-dialog-4 .mfp-close:hover,
#small-dialog .mfp-close:hover,
.slider-selection,
.cluster-visible,
.marker-container,
.custom-zoom-in:hover,
.custom-zoom-out:hover,
#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
a.apply-now-button,
.freelancer-indicators .indicator-bar span,
.roundup-indicators .indicator-bar span,
.account-indicators .indicator-bar span,
.dashboard-box .button.dark:not(.ico):hover,
.messages-inbox ul li:before,
.message-by h5 i,
.share-buttons-trigger,
.blog-compact-item,
.completed:before,
.achievement,
.goal-indicators .indicator-bar span,
.header-notifications-content .notification-icon,
.primary,
.primary-bg,
a.primary-bg,
#titlebar,
#breadcrumbs.dark,
.pagination ul li.active a,
.photo-box,
.countdown.primarycolor,
.dashboard-box .headline,
.companies-list .company img,
.message-bubble.me .message-text {
  background-color: ${primaryColor}; /*Primary Color*/
}
.emoji-wrap .emoji-invoker:hover svg,
.emoji-wrap .emoji-invoker.active svg{
  fill: ${primaryColor}77;
}

.bonus,
.photo-section,
.secondary,
.intro-search-button .button,
.countdown.secondarycolor,
.account-type input.account-type-radio:checked ~ label {
  background-color: ${secondaryColor}; /*Secondary Color*/
}

.secondary-bg{
  background-color: ${secondaryColor} !important; 
}

.banner-headline strong.color,
.bootstrap-select.btn-group .dropdown-menu li small,
.secondary-color{
  color: ${secondaryColor}; /*Secondary Color*/
}

.intro-search-field .field-title {
  background: ${secondaryColor}; /*Secondary Color*/
}

.compact-list-layout .job-listing:before,
.tasks-list-container.compact-list .task-listing:before,
.compact-list.freelancers-list-layout .freelancer:before,
.tabs-header,
.category-box:hover,
.photo-box:hover:before,
.photo-box.small .photo-box-content span,
.slick-prev:hover,
.slick-next:hover,
.bootstrap-select.btn-group .bs-actionsbox div button:hover {
  background: ${primaryColor};
}

.payment-tab-trigger > input:checked ~ label::before,
.primary-border {
  border-color: ${primaryColor};
}

.pagination ul li a.current-page ,
.pagination ul li.active a{
  box-shadow: 0 2px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25)
}

.sidebar-search-button-container button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.1);
}

.sidebar-search-button-container button:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

.job-listing.with-apply-button:hover .list-apply-button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.task-listing-bid-inner .button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.task-listing-bid-inner .button:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

.task-tags span {
  background-color: rgba(${pRed}, ${pGreen}, ${pBlue}, .07);
}

.freelancers-grid-layout .freelancer-details a.button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.1);
}

.freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.2);
}

.freelancers-list-layout .freelancer-details a.button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.freelancers-list-layout .freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

.letters-list a.current {
  box-shadow: 0 2px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

span.button, button.button, input[type="button"], input[type="submit"], a.button {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.move-on-hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.1);
}

.move-on-hover:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.2);
}

.keyword {
  background-color: rgba(${pRed}, ${pGreen}, ${pBlue}, 0.07);
}

@keyframes switch-shadow-color {
  0% {
    box-shadow: 0 0 0 0 rgba(${pRed}, ${pGreen}, ${pBlue}, 0.6);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

@keyframes radius-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(${pRed}, ${pGreen}, ${pBlue}, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.category-box:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.2);
}

.photo-box:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

.breathing-icon {
  box-shadow: 0 0 0 15px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.07);
}

@keyframes bicokAnim {
  0%, 100% {
    box-shadow: 0 0 0 15px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.07);
  }
  50% {
    box-shadow: 0 0 0 28px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.12);
  }
}

.icon-box-check {
  box-shadow: 0 0 0 5px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.1);
}

@keyframes checkAnim {
  0%, 100% {
    box-shadow: 0 0 0 5px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
}

.bidding-widget .button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.bidding-widget .button:hover {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.25);
}

.dialog-with-tabs .button {
  box-shadow: 0 4px 12px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.slider-handle:after {
  box-shadow: 0 0 0 6px rgba(${pRed}, ${pGreen}, ${pBlue}, .12);
}

.slider-handle:hover:after {
  box-shadow: 0 0 0 8px rgba(${pRed}, ${pGreen}, ${pBlue}, .12);
}

.dropdown-menu > li > a:hover, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover {
  background-color: rgba(${pRed}, ${pGreen}, ${pBlue}, 0.04);
}

@keyframes clusterAnimation {
  0%, 100% {
    box-shadow: 0 0 0 6px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
}

@keyframes markerAnimation {
  0%, 100% {
    box-shadow: 0 0 0 6px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
  }
}

a.apply-now-button {
  box-shadow: 0 2px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

a.apply-now-button:hover {
  box-shadow: 0 2px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.35);
}

.dashboard-box-list .button {
  box-shadow: 0 3px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.dashboard-box .button.dark:not(.ico):hover {
  box-shadow: 0 3px 8px rgba(${pRed}, ${pGreen}, ${pBlue}, 0.15);
}

.status-switch span.right {
	background-color: ${secondaryColor};
}
.bidding-button {
	background-color: ${secondaryColor};
}
.countdown.secondarycolor {
	background-color: ${secondaryColor};
}
.social-login-buttons .secondary-login {
	border-color: ${secondaryColor};
	color: ${secondaryColor};
}
.social-login-buttons .secondary-login:hover {
	background-color: ${secondaryColor};
}
.account-type input.account-type-radio ~ label:hover {
	background-color: ${secondaryColor};
}

.account-type input.account-type-radio:checked ~ label {
	background-color: ${secondaryColor};
}

.social-login-buttons .primary-login {
  border-color: ${primaryColor};
  color: ${primaryColor};
}

.social-login-buttons .primary-login:hover {
  background-color: ${primaryColor};
}

.social-login-buttons .secondary-login {
  border-color: ${secondaryColor};
  color: ${secondaryColor};
}

.social-login-buttons .secondary-login:hover {
  background-color: ${secondaryColor};
}

th span::after {
  border-bottom: 1px solid ${primaryColor};
}


.print-button,
.print-button:hover {
  background-color: ${primaryColor};
}

.intro-banner:after, 
.intro-banner:before {
	background: linear-gradient(to right ,rgba(${pRed}, ${pGreen}, ${pBlue},1) 30%, rgba(${pRed}, ${pGreen}, ${pBlue},.95) 53% ,rgba(${pRed}, ${pGreen}, ${pBlue},0.3) 80%, rgba(${pRed}, ${pGreen}, ${pBlue},0.2) 100%);
}

.single-page-header:after,
.single-page-header:before {
  background: linear-gradient(to right,rgba(${pRed}, ${pGreen}, ${pBlue},1) 50%,rgba(${pRed}, ${pGreen}, ${pBlue},0.93) 60%, rgba(${pRed}, ${pGreen}, ${pBlue},0.4) 80%, rgba(${pRed}, ${pGreen}, ${pBlue},0.3) 100%);
}

.slick-dots li {
  box-shadow: inset 0 0 0 2px ${primaryColor};
}

.slick-dots li.slick-active {
  box-shadow: inset 0 0 0 6px ${primaryColor};
}

.slick-dots li:after {
  background-color: ${primaryColor};
}

.notification.impact {
  background-color: rgba(${pRed}, ${pGreen}, ${pBlue}, 0.1);
}

.notification.impact h4 { font-size: 18px; margin: 3px 0 15px 0; }
.notification.impact h4,
.notification.impact,
.notification.impact a,
.notification.impact strong {
  color: ${primaryColor};
}

@media (max-width: 768px) {
  .single-page-header:after,
  .single-page-header:before {
    background-color: ${primaryColor};
    }
}

@media (max-width: 992px) {

  /* Photo Position */
  .intro-banner:after,
  .intro-banner:before {
    background: rgba(${pRed}, ${pGreen}, ${pBlue}, 1);
  }
}

.background-tile {
  background-color: ${primaryColor};
}

.comment-by a.reply {
  background-color: rgba(${pRed}, ${pGreen}, ${pBlue}, .1);
  color: ${primaryColor};
}
  `);
}

export {cssColors};
