import Vue from 'vue'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import gtag, {install as installGa} from './ga-gtag';

const EventEmitter = require('events')

window.harness_event = new EventEmitter()

if (window.localStorage.getItem('harness_event_script')) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = window.localStorage.getItem('harness_event_script');
  document.body.appendChild(script);
}

const EVENTS = {
  LEAD: 'Lead',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  PURCHASE: 'Purchase',
  SUBSCRIBE: 'Subscribe',
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  Login: 'login',
  SCREEN_TRANSITION: 'screen_transition',
};

const fbEventParams = {
  'Lead': ['content_category', 'content_name', 'currency', 'value'],
  'InitiateCheckout': ['content_category', 'content_ids', 'contents', 'currency', 'num_items', 'value'],
  'Purchase': ['content_name', 'currency', 'value'],
  'Subscribe': ['currency', 'value', 'label'],
  'AddPaymentInfo': ['content_category'],
  'CompleteRegistration': ['content_name'],
  'login': ['content_name'],
};

const FbPixelEventName = (event) => event;

const FbPixelParams = (event, params) => {
  if (event in fbEventParams) {

    if ([EVENTS.INITIATE_CHECKOUT, EVENTS.PURCHASE, EVENTS.SUBSCRIBE].indexOf(event) !== -1) {
      if (params.value) {
        params.value /= 100;
      }
    }

    return fbEventParams[event].reduce((res, item) => {
      if (item in params) {
        res[item] = params[item];
      }
      return res;
    }, {});
  }

  return {};
};

const GaEventName = (event) => {
  switch (event) {
    case 'Lead':
      return 'generate_lead';
    case 'InitiateCheckout':
      return 'begin_checkout';
    case 'Purchase':
      return 'donate';
    case 'Subscribe':
      return 'subscribe';
    case 'AddPaymentInfo':
      return 'add_payment_info';
    case 'CompleteRegistration':
      return 'sign_up';
  }

  return event;
};

const GaParams = (event, params) => {
  switch (event) {
    case 'Lead':
      return Object.assign({},
        params.content_category ? {event_label: params.content_category} : null
      );
    case 'InitiateCheckout':
      return Object.assign({},
        params.content_category ? {event_label: params.content_category} : null,
        params.value ? {value: params.value} : null
      );
    case 'Purchase':
      return Object.assign({event_category: 'ecommerce'},
        params.content_name ? {event_label: params.content_name} : null,
        params.value ? {value: params.value} : null
      );
    case 'Subscribe':
      return Object.assign({event_category: 'ecommerce'},
        params.label ? {event_label: params.label} : null,
        params.value ? {value: params.value} : null
      );
    case 'AddPaymentInfo':
      return Object.assign({},
        params.content_category ? {event_label: params.content_category} : null,
      );
    case 'CompleteRegistration':
    case 'login':
      return Object.assign({},
        params.content_name ? {method: params.content_name} : null,
      );
  }

  return params;
};

// Public functions
let isFbPixel = false;
let isGTM = false;
let isGa = false;
let gaId = '';

/**
 * Init facebook tracking pixel
 */
const initFacebookPixel = (facebook_pixel) => {
  if (isFbPixel) {
    return;
  }

  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');

  Vue.analytics.fbq.init(facebook_pixel);
  Vue.analytics.fbq.event('PageView');

  // //for initial page, pixel only
  // setTimeout(() => checkAndTrack(0, false, true), 150);

  isFbPixel = true;
};


const initGTM = (gtm_id) =>{
  if (isGTM) {
    return;
  }


  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
          new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtm_id);

  isGTM = true;
}


const initGA = (google_analytics_id, domain) => {
  if (isGa) {
    return;
  }
  gaId = google_analytics_id;

  let params = false;

  if (domain) {
    params = {
      'linker': {
        'domains': [domain, window.location.hostname]
      }
    };
  }

  installGa(google_analytics_id, params);

  isGa = true;
};

const track = (event, params, gaEnable = true, fbEnable = true) => {
  window.harness_event.emit(event, params)

  if (event !== EVENTS.SCREEN_TRANSITION) {
    if (isFbPixel && fbEnable) {
      // console.log('Fb track', event, FbPixelParams(event, params));
      Vue.analytics.fbq.event(FbPixelEventName(event), FbPixelParams(event, params));
    }

    if (isGa && gaEnable) {
      // console.log('gtag event', GaEventName(event), GaParams(event, params));
      gtag('event', GaEventName(event), GaParams(event, params))
    }
  }
};

function checkAndTrack(i = 0, ga = true, fbPixel = true) {
  if (document.title.split('|')[0].trim() === '' && i < 5) {
    i++;
    setTimeout(() => checkAndTrack(i, ga, fbPixel), 150);
  } else {
    if (ga && gaId) {
      gtag('config', gaId, {
        page_title: document.title,
        page_location: location.href,
        page_path: location.pathname
      })
    }

    if (fbPixel) {
      Vue.analytics.fbq.event('ViewContent', {content_category: document.title})
    }
  }
}

/**
 * Vue installer
 */
const install = (Vue, {router}) => {
  Vue.use(VueFacebookPixel);

  // These objects may contain different providers as well,
  // or might be empty:
  if (!Vue.tracking) {
    Vue.tracking = {}
  }

  router.afterEach((to) => {
    if(!(to.name === 'Donate' && to.query && to.query.new)){
      //wait for title and track manually
      setTimeout(() => checkAndTrack(), 150);
    }
  });


  if (!Vue.prototype.$tracking) {
    Vue.prototype.$tracking = {}
  }

  // Setting values for both Vue and component instances
  // Usage:
  // 1. `Vue.analytics.fbq.init()`
  // 2. `this.$analytics.fbq.init()`

  Vue.tracking = {initFacebookPixel, initGA, initGTM, track, EVENTS};
  Vue.prototype.$tracking = {initFacebookPixel, initGA, initGTM, track, EVENTS}
};

export default {install}