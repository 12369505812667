export default {
  methods: {
    onVerify (response) {
      this.captchaKey = response;
    },
    onExpired () {
      this.captchaKey = '';
    },
  },
  data() {
    return {
      captchaKey: '',
    }
  }
}