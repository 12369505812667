import Vue from 'vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSpinner, faTimesCircle, faCity, faArrowRight} from '@fortawesome/pro-solid-svg-icons'
import {
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faLockAlt,
    faBriefcase,
    faLongArrowAltRight,
    faArrowAltCircleUp,
    faArrowDown,
    faAt,
    faEdit,
    faUserCircle as farUserCircle,
    faClipboardUser,
    faBirthdayCake,
    faMapMarkerAlt,
    faMapMarkerPlus,
    faUndo,
    faChartBar,
    faBellOn,
    faBellSlash,
    faAngleLeft,
    faRepeat,
    faCheckCircle,
    faPhoneAlt,
    faCheck,
    faPlus,
    faPlusCircle,
    faPaintBrushAlt,
    faHeart,
    faSearch,
    faPowerOff,
    faCalendar,
    faWallet,
    faChartPieAlt,
    faTrashAlt,
    faCommentAltDots,
    faQuestionCircle as farQuestionCircle,
    faBars,
    faHome,
    faFileAlt as farFileAlt,
    faClipboardList,
    faDollarSign,
    faUsdCircle,
    faUsdSquare,
    faCreditCard,
    faPaste,
    faPrint,
    faFingerprint,
    faTimesCircle as farTimesCircle,
    faEllipsisV,
    faClock,
    faSave,
    faDesktop,
    faLink as farLink,
    faComment,
    faToggleOn,
    faThumbsUp,
    faBuilding,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faFileAlt,
    faLink,
    faCommentsAlt,
    faQuestionCircle,
    faWrench,
    faIdCard,
    faUserCircle,
    faInfoCircle,
    faStreetView,
    faCheck as falCheck
} from '@fortawesome/pro-light-svg-icons'

import {
    faFacebookF,
    faFacebook,
    faFacebookSquare,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faYoutube,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faCcJcb,
    faCcVisa,
    faCcDinersClub,
} from '@fortawesome/free-brands-svg-icons'

library.add(faSpinner, faTimesCircle, faChevronLeft,
    faFacebookF, faFacebook, faFacebookSquare, faTwitter, faLinkedinIn, faInstagram, faYoutube,
    faCcMastercard, faCcAmex, faCcDiscover, faCcJcb, faCcVisa, faCcDinersClub,
    faFileAlt, faLink, faCommentsAlt, faQuestionCircle, faWrench,
    faEnvelope, faLockAlt, faBriefcase, faIdCard, faUserCircle,
    faLongArrowAltRight, faAt, faEdit, farUserCircle, faBirthdayCake, faMapMarkerAlt, faMapMarkerPlus, faCity,
    faUndo, faChartBar, faBellOn, faBellSlash, faAngleLeft, faRepeat, faCheckCircle, faPhoneAlt, faCheck, faPlus,
    faPaintBrushAlt, faInfoCircle, faHeart, faSearch, faChevronRight, faPowerOff, faArrowAltCircleUp, faCalendar,
    faWallet, faStreetView, faChartPieAlt, faTrashAlt, faCommentAltDots, farQuestionCircle, faBars, faHome, farFileAlt,
    faClipboardList, faDollarSign, faCreditCard, falCheck, faUsdCircle, faPlusCircle, faUsdSquare, faClipboardUser,
    faPaste, faPrint, faFingerprint, farTimesCircle, faEllipsisV, faArrowDown, faClock, faSave, faDesktop, farLink,
    faComment, faToggleOn, faThumbsUp, faBuilding, faArrowRight

);

Vue.component('font-awesome-icon', FontAwesomeIcon);