import Vue from 'vue';
import {getAllDesignations} from '../../utils/api'

const moment = require('moment');

const state = {
  allDesignations: [],
  lastUpdate: null
};

// actions
const actions = {
  updateDesignations({commit, state}, {charity_id, force = false}) {
    return new Promise((resolve) => {

      //cache for 2 minutes
      if (state.lastUpdate === null || force || moment().diff(state.lastUpdate, 'seconds') > 60 * 2) {
        getAllDesignations(charity_id).then(designations => {

          commit('setDesignations', designations.designations.sort((d1, d2) =>  d1.name.localeCompare(d2.name)));
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  },
};

// mutations
const mutations = {
  setDesignations(state, allDesignations) {

    Vue.set(state, 'lastUpdate', moment());
    Vue.set(state, 'allDesignations', allDesignations);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};