import Vue from 'vue';
import {getAllImpactPosts} from '../../utils/api'

const moment = require('moment');

const state = {
  allPosts: [],
  lastUpdate: null,
  lastViewedDate: null,
};

// actions
const actions = {
  updatePosts({commit, state}, {charity_id, force = false}) {
    return new Promise((resolve) => {
      //cache for 5 minutes
      if (state.lastUpdate === null || force || moment().diff(state.lastUpdate, 'seconds') > 60 * 5) {
        getAllImpactPosts(charity_id).then(posts => {
          commit('setPosts', posts);
          resolve(posts);
        }).catch(() => {
          commit('setPosts', []);
          resolve();
        });
      } else {
        resolve();
      }
    });
  },
};

// mutations
const mutations = {
  setPosts(state, allPosts) {
    Vue.set(state, 'lastUpdate', moment());
    Vue.set(state, 'allPosts', allPosts);
  },
  resetCache(state) {
    Vue.set(state, 'lastUpdate', null);
    Vue.set(state, 'allPosts', []);
  },
  setPostsLastViewedDate(state, date) {
    Vue.set(state, 'lastViewedDate', date);
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};