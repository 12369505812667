<template>
  <div id="app" :class="{'header-disable': header_disable, 'invoice-page': ['DonationHistoryReceipt', 'DonationReceipt'].indexOf($route.name) !== -1}">
    <router-view
        v-if="charityInfo && $route.meta.simpleLayout"
        :isLogin="!!isLogin"
        :donorDetail="donorDetail"
        :charityInfo="charityInfo"
    ></router-view>
    <div v-else id="wrapper">
      <nav-header
          v-if="!header_disable"
          :isLogin="!!isLogin"
          :donorDetail="donorDetail"
          :charityInfo="charityInfo"
          v-on:open-login-modal="openLoginModal"
          v-on:logout="logout"
      ></nav-header>
      <div v-if="charityInfo && !charityInfo.payments_enabled"
           class="notification error margin-bottom-0 padding-bottom-15 padding-top-15">
        <p><b>Ready to start fundraising?</b> Finish setting up your organization to make this page live -
          <a href="https://admin.harnessapp.com/" target="_blank">Finish Setup</a></p>
      </div>
      <router-view
          v-if="charityInfo"
          :isLogin="!!isLogin"
          :donorDetail="donorDetail"
          :charityInfo="charityInfo"
          v-on:update-donor-details="updateDonorDetails()"
          v-on:open-login-only-modal="openLoginModalOnly"
          v-on:open-login-modal="openLoginModal"
          v-on:logout="logout"
      ></router-view>
      <magnific-popup-modal :show="false"
                            :config="$root.popupConfig"
                            ref="loginOnlyModal"
                            :id="'sign-in-dialog'"
                            :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
      >
        <div class="sign-in-form">
          <div class="popup-tabs-container">
            <div class="popup-tab-content">
              <div class="welcome-text">
                <h3>Login to Account</h3>
                <span>We're glad to see you again!</span>
              </div>

              <login-form :charityInfo="charityInfo"
                          :initialEmail="initialEmail"
                          v-on:update-donor-details="$refs.loginOnlyModal.close();updateDonorDetails()"
              ></login-form>
            </div>
          </div>
        </div>
      </magnific-popup-modal>
      <magnific-popup-modal :show="false"
                            :config="$root.popupConfig"
                            ref="confirmPhoneModal"
                            :id="'sign-in-dialog'"
                            v-on:close="updateDonorDetails"
                            :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
      >
        <div class="sign-in-form">
          <ul class="popup-tabs-nav">
            <li><a href="#phone-number">Phone Number</a></li>
          </ul>
          <div class="popup-tabs-container">
            <div class="popup-tab-content" id="phone-number">
              <confirm-phone-form
                  :phone-number="phoneNumber"
                  :charityInfo="charityInfo"
                  v-on:update-donor-details="$refs.confirmPhoneModal.close();updateDonorDetails"
                  v-on:phone-verified="$refs.confirmPhoneModal.close();updateDonorDetails()"
              ></confirm-phone-form>
            </div>
          </div>
        </div>
      </magnific-popup-modal>
      <magnific-popup-modal :show="false"
                            :config="$root.popupConfig"
                            ref="loginModal"
                            :id="'sign-in-dialog'"
                            v-on:close="updateDonorDetails();isLoginModalOpen = false"
                            v-on:open="onOpenLoginModal();isLoginModalOpen = true"
                            :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
      >

        <div class="sign-in-form">

          <ul class="popup-tabs-nav">
            <li><a href="#login">Log In</a></li>
            <li><a href="#register">Register</a></li>
          </ul>

          <div class="popup-tabs-container">

            <!-- Login -->
            <div class="popup-tab-content" id="login">

              <div class="welcome-text">
                <h3>Login to Account</h3>
                <span>Don't have an account? <a href="#" class="register-tab">Sign Up!</a></span>
              </div>
              <login-form :charityInfo="charityInfo"
                          v-on:update-donor-details="$refs.loginModal.close();successRedirect();updateDonorDetails()"
              ></login-form>
            </div>

            <!-- Register -->
            <div class="popup-tab-content" id="register">

              <div class="welcome-text">
                <h3>Create an Account</h3>
                <span>Already have an account? <a href="#" class="login-tab">Log In!</a></span>
              </div>
              <register-form :charityInfo="charityInfo"
                             v-on:update-donor-details="$refs.loginModal.close();successRedirect();updateDonorDetails()"
                             v-on:confirm-phone="goConfirmPhone"
                             v-on:recaptcha-execute="$refs.recaptcha.execute()"
                             v-on:recaptcha-reset="$refs.recaptcha.reset();$refs.registerForm.onExpired()"
                             :harness_fields="donationConfig && donationConfig.harness_fields || {'phone_number': {'enabled': false},'address': {'enabled': false}}"
                             ref="registerForm"
                             :initialEmail="initialEmail"
              ></register-form>
            </div>
          </div>
        </div>
      </magnific-popup-modal>

      <vue-recaptcha v-if="isLoginModalOpen"
                     :sitekey="$root.config.google.recaptcha_key_invisible ? $root.config.google.recaptcha_key_invisible : '6Ld0WrsUAAAAAOawnDvZtuFRpPtwuV7ptlpxJJjW'"
                     :loadRecaptchaScript="true"
                     ref="recaptcha"
                     size="invisible"
                     @verify="$refs.registerForm.onVerifyInvisible"
                     @expired="$refs.registerForm.onExpired"
      ></vue-recaptcha>


      <magnific-popup-modal :show="false"
                            :config="$root.popupConfig"
                            ref="fixAccountModal"
                            :id="'small-dialog-3'"
                            v-on:close="skipFix"
                            :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
      >
        <!--Tabs -->
        <div class="sign-in-form">

          <ul class="popup-tabs-nav">
            <li><a href="#error">Error</a></li>
            <li><a href="#details">Details</a></li>
          </ul>

          <div class="popup-tabs-container">

            <!-- Tab -->
            <div class="popup-tab-content" id="error">

              <!-- Welcome Text -->
              <div class="welcome-text">
                <h3>Round-Ups Error</h3>
                <p class="margin-top-5">We noticed that your card has a Round-Ups error:</p>
                <div v-if="authCard && authCard.metadata" class="remove-teammate margin-bottom-10">
                  <img :src="getCardImage(authCard.metadata.brand)" style="height: 22px" class="margin-right-8" alt="">
                  {{authCard.metadata.brand}} {{authCard.metadata.last4}}
                </div>
                <span class="company-logo"><img v-if="$refs.fixAccountModal && $refs.fixAccountModal.visible"
                                                :src="require('./assets/roundups_fallback_error_new.png')"
                                                alt=""></span>

              </div>

              <!-- Button -->
              <button class="margin-top-0 button full-width button-sliding-icon ripple-effect"
                      v-on:click.prevent="$router.push({name: 'RoundupsConnectCard', query: {'fix-account': brokenAccount.accountId}})"
              >Fix Round-Ups Error
                <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
              </button>

            </div>

            <!-- Register -->
            <div class="popup-tab-content" id="details">

              <!-- Welcome Text -->
              <div class="welcome-text">
                <h3>More Details</h3>
                <!--<span>Frequently Asked Questions</span>-->
              </div>

              <!-- FAQ Answers -->

              <p><a href="#">Why am I receiving this Error?</a><br>Your bank is requiring an update to your Round-Ups
                authorization for this card. Due to this error, we are no longer receiving your "Round-Up" spare change
                donations.</p>
              <p><a href="#">What if I can't fix this Error?</a><br>Not able to fix your account? We'll change your
                Round-Ups into a monthly gift with our Auto Error Update (after 10 Days).</p>
              <p><a href="#">How much will the Auto-Update Monthly amount be?</a><br>We'll make sure it's lower than
                your Average Round-Up amount (or your Monthly Cap) so you're not giving more than your normal Round-Ups
                before the error.</p>
              <div class="social-login-buttons">
                <button class="secondary-login ripple-effect"
                        onclick="window.location.href='https://donor-support.drift.help/'"
                ><font-awesome-icon class="margin-right-10" :icon="['fal', 'question-circle']"/>Questions?
                </button>
                <button class="secondary-login ripple-effect"
                        v-on:click.prevent="$router.push({name: 'RoundupsConnectCard', query: {'fix-account': brokenAccount.accountId}})"
                ><font-awesome-icon class="margin-right-10" :icon="['fal', 'wrench']"/>Fix Error
                </button>
              </div>

            </div>

          </div>
        </div>
      </magnific-popup-modal>

    </div>
    <nav-footer
        v-if="$route.meta && $route.meta.withFooter"
        :isLogin="!!isLogin"
        :donorDetail="donorDetail"
        :charityInfo="charityInfo"
    ></nav-footer>
    <big-footer
        v-if="$route.meta && $route.meta.bigFooter"
        v-on:open-login-modal="openLoginModal"
        :isLogin="!!isLogin"
        :donorDetail="donorDetail"
        :charityInfo="charityInfo"
        :show-middle-bottom="$route.meta.hasOwnProperty('showMiddleBottom') ? $route.meta.showMiddleBottom : true"
    ></big-footer>

    <template v-if="isProxy && donorDetail" >
      <div class="notification-admin notification bg-secondary margin-bottom-0 padding-bottom-15 padding-top-15 text-center">
        <p>
          <b>Viewing as {{donorDetail.first_name}} {{donorDetail.last_name}}</b> - To end this session, you can close this tab or
          <a href="#" v-on:click.prevent="logout">Logout</a>
        </p>
      </div>
    </template>

  </div>
</template>

<script>
  import $ from 'jquery'
  import {getWhitelabelReq, userAccount, roundupAccount, fundingSource, getDonationFlow} from './utils/api'
  import {inIframe} from './utils/utils'
  import {getHarnessId} from './utils/api'
  import {cssColors} from './utils/colors-css'
  // import { LDClient } from 'launchdarkly-js-client-sdk';

  import NavHeader from './components/Parts/nav-header.vue'
  import NavFooter from './components/Parts/footer.vue'
  import BigFooter from './components/Parts/big-footer.vue'
  import MagnificPopupModal from './components/Parts/MagnificPopupModal'
  import LoginForm from './components/Parts/login-form.vue'
  import RegisterForm from './components/Parts/register-form.vue'
  import ConfirmPhoneForm from './components/Parts/confirm-phone-form.vue'
  import configMixin from './components/Flow/config-mixin'
  import cardMixin from './components/Donations/card-mixin';
  import {mapMutations} from 'vuex'
  import VueRecaptcha from 'vue-recaptcha';

  import Vue from 'vue'

  export default {
    name: 'app',
    metaInfo() {
      const res = {
        title: 'Donation',
        titleTemplate: '%s | ' + (this.charityInfo ? this.charityInfo.charity_name : ''),
      };

      if (this.charityInfo) {

        let prColor = this.charityInfo.primary_color;
        let sdColor = this.charityInfo.secondary_color;

        if ('meta' in this.$route && 'invertedColors' in this.$route.meta && this.$route.meta.invertedColors) {
          prColor = this.charityInfo.secondary_color;
          sdColor = this.charityInfo.primary_color;
        }

        let style = [];

        style.push({
          vmid: 'page-colors-css',
          innerHTML: `.text-primary{color: ${prColor} !important;}
          .vb>.vb-dragger>.vb-dragger-styler{background-color: ${prColor};}
          .text-secondary{color: ${sdColor};}
          .bg-primary, body .noUi-connect{ background-color: ${prColor}; border-color: ${prColor};fill: ${prColor};}
          body .vue-switcher.vue-switcher-theme--default.vue-switcher-color--default div:before,
          body .vue-switcher.vue-switcher-theme--default.vue-switcher-color--default div:after{ background-color: ${prColor}; border-color: ${prColor};fill: ${prColor};}
          .bg-secondary{background-color: ${sdColor};border-color: ${sdColor};fill: ${sdColor};}
          .bg-secondary{background-color: ${sdColor};border-color: ${sdColor};fill: ${sdColor};}
          .checkbox-container input:checked ~ .checkmark{background-color: ${prColor};border-color: ${prColor};}`,
        });

        style.push({
          vmid: 'page-colors-css-2',
          innerHTML: cssColors(prColor, sdColor),
        });

        res['style'] = style;
      }

      return res;
    },
    mixins: [configMixin, cardMixin],
    components: {
      NavHeader,
      MagnificPopupModal,
      LoginForm,
      RegisterForm,
      ConfirmPhoneForm,
      NavFooter,
      BigFooter,
      VueRecaptcha
    },
    created() {
      window.addEventListener('beforeunload', this.leaving)

      this.resetSettings();
      if (this.$route.query && 'version' in this.$route.query) {
        if (this.$route.query.version === 'desktop1') {
          try {
            localStorage.setItem('desktop', 1);
          } catch (e) {
            return null;
          }

        } else {
          localStorage.removeItem('desktop');
        }

        let query = Object.assign({}, this.$route.query);
        delete query.version;
        this.$router.replace({query});
      }
      if (this.$route.query && 'header' in this.$route.query) {
        if (this.$route.query.header === 'disable') {
          try {
            localStorage.setItem('header_disable', 1);
          } catch (e) {
            return null;
          }
        } else {
          localStorage.removeItem('header_disable');
        }

        let query = Object.assign({}, this.$route.query);
        delete query.header;
        this.$router.replace({query});
      }
    },
    mounted() {
      this.inIframe = inIframe();
      this.refreshInfo();

      if (localStorage.header_disable) {
        this.header_disable = true;
      }
    },
    methods: {
      ...mapMutations('donation', [
        'resetSettings'
      ]),
      leaving() {
        if (this.isProxy) {
          this.logout();
        }
      },
      onOpenLoginModal() {
        if(this.charityInfo){
          this.flowId = this.$route.query && (this.$route.query['flow-id'] || this.$route.query['checkout']) || this.charityInfo.default_flow_id || 0;

          getDonationFlow(this.charityInfo.charity_id, this.flowId).then(({flow}) => {
            flow = this.normalizeFlow(flow);

            //save flow config
            this.setConfig(flow);
          });
        }
      },
      successRedirect() {
        if (this.$route.query && this.$route.query.auth) {
          this.$router.push(this.$route.query.auth);
        }
      },
      goConfirmPhone(phoneNumber) {
//        this.$refs.loginModal.close();
        this.phoneNumber = phoneNumber;
        this.$refs.confirmPhoneModal.open();
      },
      async refreshInfo() {
        getWhitelabelReq().then(charityInfo => {

          const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/png';
          link.rel = 'shortcut icon';
          link.href = charityInfo.logo_square || charityInfo.logo_transparent;

          this.charityInfo = charityInfo;

          if (charityInfo.addons && charityInfo.addons.google_analytics) {

            try {
              const matches = charityInfo.website.match(/^(https?:\/\/)?(?:www\.)?([^/:?#]+)(?:[/:?#]|$)/i);
              const domain = matches && matches[2] || charityInfo.website;

              Vue.tracking.initGA(charityInfo.addons.google_analytics, domain);
            }
            catch (e) {
              // eslint-disable-next-line no-console
              console.warn(e.message);
            }
          }

          if (charityInfo.addons && charityInfo.addons.google_tag_manager) {
            Vue.tracking.initGTM(charityInfo.addons.google_tag_manager);
          }

          if (this.charityInfo && this.charityInfo.addons && this.charityInfo.addons.facebook_pixel) {
            Vue.tracking.initFacebookPixel(this.charityInfo.addons.facebook_pixel);
          }
        });
        await this.updateDonorDetails();
      },
      async updateDonorDetails() {
        let resolveInitPromise
        let rejectInitPromise
        const initPromise = new Promise((resolve, reject) => {
          resolveInitPromise = resolve
          rejectInitPromise = reject
        })
        this.isLogin = window.localStorage.getItem('jwt');
        if (this.isLogin) {
          userAccount.getDonorDetails().then(donorDetail => {
            this.donorDetail = donorDetail;
            resolveInitPromise(donorDetail)
          }).catch((Error) => {
            rejectInitPromise(Error)
          })
        } else {
          rejectInitPromise(this.isLogin)
        }

        return initPromise
      },
      skipFix() {
        if (this.$route.name !== 'RoundupsConnectCard') {
          let skipCheckAccounts = window.localStorage.getItem("skipCheckAccounts") ? JSON.parse(window.localStorage.getItem("skipCheckAccounts")) : [];
          skipCheckAccounts.push(this.brokenAccount.accountId);
          try {
            window.localStorage.setItem("skipCheckAccounts", JSON.stringify(skipCheckAccounts))
          } catch (e) {
            return null;
          }

        }
      },
      openLoginModalOnly(email = false) {
        this.$refs.loginOnlyModal.open();
        if (email) {
          this.initialEmail = email;
        } else {
          this.initialEmail = '';
        }
      },
      openLoginModal(tab = 'login', email = false) {
        this.$refs.loginModal.open();
        if (tab === 'register') {
          $('.register-tab').click();
        } else {
          $('.login-tab').click();
        }
        if (email) {
          this.initialEmail = email;
        } else {
          this.initialEmail = '';
        }
      },
      logout() {
        window.localStorage.clear();
        this.updateDonorDetails();
        this.$router.push({name: 'Donate'});
      },
    },
    watch: {
      'charityInfo'() {
        if (this.charityInfo && this.isLogin && ['RoundupsConnectCard'].indexOf(this.$route.name) === -1) {
          roundupAccount.getAllRoundupAccounts().then(accounts => {
            const skipCheckAccounts = window.localStorage.getItem("skipCheckAccounts") ? JSON.parse(window.localStorage.getItem("skipCheckAccounts")) : [];

            this.brokenAccount = accounts.reduce((res, account) => {
              return res ? res : ((account.fundingSourceId && account.errorCode && skipCheckAccounts.indexOf(account.accountId) === -1) ? account : res);
            }, null);

            if (this.brokenAccount) {
              fundingSource.getAllFundingSources().then(sources => {
                this.authCard = sources.reduce((res, item) => item.funding_source_id === this.brokenAccount.fundingSourceId ? item : res, null);
              });
              if (this.$refs.fixAccountModal) {
                this.$refs.fixAccountModal.open();
              }
            }
          });
        }
      }, 
      multiContext: {
        handler: async function (newVal) {
          if (newVal === null) {
            return;
          }
          
          if (!this.$launchDarkly.isInitialized()) {
            this.$launchDarkly.initialize(newVal);
          } else {
            const ldClient = await this.$launchDarkly.getClient();
            ldClient.identify(newVal);
          }
        },
        deep: true,
      }
    },
    computed: {
      isProxy() {
        if (this.isLogin) {
          const tokenParts = window.localStorage.getItem('jwt').split(".");

          if (tokenParts.length > 1) {
            try {
              return JSON.parse(window.atob(tokenParts[1])).proxy;
            } catch (e) {
              return false;
            }
          }
        }
        return false;
      },
      multiContext() {
        const userContext = this.userContext;
        const charityContext = this.charityContext;

        // Wait until both contexts are loaded
        if (!userContext || !charityContext) {
          return null;
        }

        return {
          kind: 'multi',
          user: this.userContext,
          charity: this.charityContext
        };
      },
      userContext() {
        const baseContext = {
          kind: 'user',
          anonymous: !this.isLogin
        }

        if (this.isLogin && this.donorDetail) {
          return {
            ...baseContext,
            key: getHarnessId(),
            firstName: this.donorDetail.first_name || null,
            lastName: this.donorDetail.last_name || null,
            email: this.donorDetail.email || null
          }
        } else if (this.isLogin && !this.donorDetail) {
          // Return null until the donor details have been fully loaded
          return null;
        }

        return baseContext
      },
      charityContext() {
        return this.charityInfo
      ? {
          kind: 'charity',
          key: this.charityInfo.charity_id,
          name: this.charityInfo.charity_name,
          paymentsEnabled: this.charityInfo.payments_enabled,
          subdomain: this.charityInfo.subdomain
        }
      : null
      }
    },
    data() {
      return {
        flowId: 0,
        charityInfo: null,
        donorDetail: null,
        initialEmail: '',
        inIframe: false,
        isLogin: window.localStorage.getItem('jwt'),
        phoneNumber: '',
        header_disable: false,
        brokenAccount: null,
        authCard: null,
        isLoginModalOpen: false,
      }
    },
  }
</script>
